/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";
import { NullOrEmpty } from "../utils"

export const getAutoP2PPayments = (query) => {
  var {
    paymentType,
    paymentFilter
  } = query;

  var sortBy = "-_id";
  var status = '';

  if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID) {
    sortBy = "timestamp_step2";
    status = CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_2;
  } else if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID) {
    // status = CONFIGS.AUTOP2P_PAYMENT_STATUS.CON;
    sortBy = "-timestamp_step3";
  }

  var strQuery = `type=${paymentType}`;

  if (status)
    strQuery += `&status=${status}`

  strQuery += `&tmp=${Date.now()}&sort=${sortBy}&limit=50`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/autop2p/get?${strQuery}`);
}

export const updateAutoP2PPayments = (payments) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/autop2p`, payments);
}

export const autoP2PService = {
  getAutoP2PPayments,
  updateAutoP2PPayments
};
