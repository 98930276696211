/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";
import { NullOrEmpty } from "../utils"

export const getShopSoldStat = (query) => {
  var {
    shopName,
    type,
    sortBy,
    timeMin,
    timeMax
  } = query;

  query = `sort=${sortBy}`;

  if (!NullOrEmpty(shopName))
    query += `&shop_names=${shopName}`;

  if (!NullOrEmpty(type))
    query += `&type=${type}`;

  if (!NullOrEmpty(timeMin))
    query += `&timestamp>=${timeMin}`;

  if (!NullOrEmpty(timeMax))
    query += `&timestamp<=${timeMax}`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/erank-stat/get?${query}`); //&t=${Math.floor(Math.random()*1000)}
};

export const getShopSoldStatGroup = (query) => {
  var {
    shopList,
    type,
    sortBy,
    timeMin,
    timeMax
  } = query;

  query = `sort=${sortBy}`;

  if (!NullOrEmpty(shopList))
    query += `&shop_list=${shopList}`;

  if (!NullOrEmpty(type))
    query += `&type=${type}`;

  if (!NullOrEmpty(timeMin))
    query += `&timestamp>=${timeMin}`;

  if (!NullOrEmpty(timeMax))
    query += `&timestamp<=${timeMax}`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/erank-stat/get-group?${query}`); //&t=${Math.floor(Math.random()*1000)}
};

export const getShopSold = (query) => {
  var {
    shopName,
    type,
    timeMin,
    timeMax,
    sortBy,
    limit
  } = query;

  let sort = sortBy? sortBy: 'timestamp';

  query = `sort=${sort}`;

  if (!NullOrEmpty(shopName))
    query += `&shop_name=${shopName}`;

  if (!NullOrEmpty(limit))
    query += `&limit=${limit}`;

  if (!NullOrEmpty(timeMin))
    query += `&timestamp>=${timeMin}`;

  if (!NullOrEmpty(timeMax))
    query += `&timestamp<=${timeMax}`;

  // if (type != "")
  //   query += `&type=${type}`;

  // if (timeMin != "")
  //   query += `&timestamp>=${timeMin}`;

  // if (timeMin != "")
  //   query += `&timestamp<=${timeMax}`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/erank/get?${query}`);
};

export const shopSoldService = {
  getShopSoldStat,
  getShopSoldStatGroup,
  getShopSold
};
