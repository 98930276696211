/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Listing from "views/Listing";
import Collection from "views/Collection";
import CollectionDetail from "views/CollectionDetail";
import View3D from "views/View3D";
import Shop from "views/Shop";
import ShopSold from "views/ShopSold";
import Bot from "views/Bot";
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import View3DLamp from "views/View3DLamp";
import BoxMaker from "views/BoxMaker";
import AutoP2P from "views/AutoP2P";
import { getTranslate, LANGUAGE_KEYS } from "utils";

const dashboardRoutes = [
  {
    path: "/autop2p-buy",
    name: "MUA",
    icon: "nc-icon nc-cart-simple",
    component: AutoP2P,
    layout: "/admin"
  },
  {
    path: "/autop2p-sell",
    name: "BÁN",
    icon: "nc-icon nc-money-coins",
    component: AutoP2P,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-atom",
    component: Icons,
    layout: "/admin"
  }
];

export default dashboardRoutes;
