import { CONFIGS } from "configs";
import { TYPES } from "../actions";

const initialState = {
  autoP2PPayments: {},
  timestampGet: Date.now()
};

export const autoP2PReducer = (state = initialState, action) => {
  switch (action.type) {

    case TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENTS_SUCCESS:
      return { ...state, 
          autoP2PPayments: action.payload.data.result,
          timestampGet: Date.now()
      };

    case TYPES.ACTION_UPDATE_AUTOP2P_PAYMENT_SUCCESS:
      var updatedPayment = action.payload.payment;

      return { ...state, 
          autoP2PPayments: autoP2PPayments.map(payment => (
            (payment.order_id == updatedPayment.order_id)?
            updatedPayment: payment
          ))
      };
      
    default:
      return state;
  }
};
