/* eslint-disable func-names */
import React, { Component, createRef  } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { setFlag, getShopSoldStat, getShopSold, getLatestShopSold } from '../actions';
import { CONFIGS } from '../configs';
import { ToastContainer, toast } from 'react-toastify';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table
  } from "react-bootstrap";

import { FaChartPie } from "react-icons/fa";
import socket from '../socket';

import moment from "moment";

class ShopSoldChart extends Component {
  constructor() {
    super();
    this.chartRef = createRef();
    this.state = {
      chartData: [],
      latestShopSold: "",
      highlightedIndex: true,
      hourlySoldData: [],
      dailySoldData: []
    };
  }

  fetchData(viewMode, shopTeam) {
    if (shopTeam) {
      var shopNames = shopTeam.split(",");
      // shopNames.map(shopName => this.props.getLatestShopSold(shopName));
    } else {
      this.props.getLatestShopSold(this.props.shopName);
    } 
  }

  componentDidMount() {
    this.fetchData(this.props.viewMode, this.props.shopTeam);
    
    this.interval = setInterval(() => {
      this.updateHighlight();
    }, 900);
  }

  highlightedIndex = true;

  updateHighlight = () => {
    this.highlightedIndex = !this.highlightedIndex;

    try {
      const chart = this.chartRef.current.chart;
      const viewMode = this.props.viewMode;
      var currentIndex = 0;

      switch (viewMode) {
        case CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY:
          currentIndex = this.state.hourlySoldData.length - 1 - 24;
          break;

        case CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY:
          currentIndex = this.state.hourlySoldData.length - 1 - 12;
          break;

        case CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS:
          currentIndex = 6;
          break;

        case CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS:
          currentIndex = 13;
          break;

        case CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS:
          currentIndex = 29;
          break;
      }

      this.chartData.forEach((item, index) => {
        chart.series[0].data[index].update({
          color: index === currentIndex && this.highlightedIndex ? 'red' : '#2CAFFE',
        }, false); // false để tránh re-render toàn bộ
      });
  
      chart.redraw(); // Cập nhật thay đổi mà không cần render lại toàn bộ component
    } catch {

    }

    socket.on('message', (message) => { 
      console.log(message);
      // this.props.addMessage(getTimeNow() + " From server : " + message);
    })
  };

  renderOptions = () => {
    const { latestShopSold, shopName, hourlySoldData, dailySoldData } = this.state;
    const { viewMode } = this.props;
    var totalSold = 0;
    var chartName = "";

    var data = [];
    var yMax = this.props.shopTeam? 50: 30;

    if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY 
      || viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY
    ) {
      const currentDate = moment().format('DD/MM');
      chartName = `Đơn Etsy ngày ${currentDate}`;

      if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY)
        chartName = "Black Friday 2024 - Giờ US"

      let dHour = (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY) ? 24: 12;
      let shopSoldToday = (hourlySoldData && hourlySoldData.length > 0) ? hourlySoldData.slice(1 + dHour): [];

      var data = shopSoldToday.map(soldData => {
        totalSold += soldData.delta_sold;
        const date = new Date(soldData.timestamp * 1000);
  
        // Lấy giờ từ đối tượng Date
        // const hours = date.getHours(); 
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
  
        return [date.getTime(), soldData.delta_sold]
      });
  
      if (latestShopSold && hourlySoldData.length > 0) {
        var lastHourTotalSold = hourlySoldData[hourlySoldData.length-1].total_sold;
        var currentHourTotalSold = latestShopSold.total_sold;
        var currentHourDeltaSold = currentHourTotalSold - lastHourTotalSold;
        totalSold += currentHourDeltaSold;
  
        const date = new Date(latestShopSold.timestamp * 1000);

        // Lấy giờ từ đối tượng Date
        const hours = date.getHours(); 
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        
        if (currentHourDeltaSold>0)
          data.push([date.getTime(), currentHourDeltaSold])
      }
  
      const now = new Date();
  
      for (var i = data.length; i<23; i++) {
        let date = new Date(now.getTime());

        // Lấy giờ từ đối tượng Date
        date.setHours(i + dHour - 24, 0, 0, 0); 
  
        data.push([date.getTime(), 0])
      }
    } else if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS
      || viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS
      || viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS
    ) {
      var numberOfDays = 0;

      if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS)
        numberOfDays = 7;
      else if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS)
        numberOfDays = 14;      
      else if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS)
          numberOfDays = 30;

      const fromDay = moment().subtract(numberOfDays - 1, 'days').format('DD/MM');
      const today = moment().format('DD/MM');
      chartName = `Đơn Etsy từ ${fromDay} đến ${today}`;
      yMax = this.props.shopTeam? 400: 200;
  
      if (dailySoldData.length > 1)
        for (var i = numberOfDays - 1; i >= dailySoldData.length; i--) {
          const time = moment().subtract(i, 'days') // Trừ i ngày
          .startOf('day')      // Đặt về 0 giờ, 0 phút, 0 giây
          .valueOf();
    
          data.push([time, 0])
        }

      var startIndex = Math.max(dailySoldData.length - numberOfDays + 1, 1);

      for (var i=startIndex; i<dailySoldData.length; i++) {
        var deltaSold = dailySoldData[i].total_sold - dailySoldData[i-1].total_sold;

        const date = new Date(dailySoldData[i].timestamp * 1000);
        date.setHours(0, 0, 0, 0);

        data.push([date.getTime(), deltaSold]);

        totalSold += deltaSold;
      }
  
      if (latestShopSold && dailySoldData.length > 0) {
        var yesterdayTotalSold = dailySoldData[dailySoldData.length-1].total_sold;
        var todayTotalSold = latestShopSold.total_sold;
        var todayDeltaSold = todayTotalSold - yesterdayTotalSold;
        totalSold += todayDeltaSold;
  
        const date = new Date(latestShopSold.timestamp * 1000);
        date.setHours(0, 0, 0, 0);
        
        if (todayDeltaSold>0)
          data.push([date.getTime(), todayDeltaSold])
      } 
      
      if (data.length < numberOfDays) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        data.push([now.getTime(), 0])
      }
    }

    this.chartData = data;

    if (this.props.updateTOP2TeamChartData && data.length > 0)
      this.props.updateTOP2TeamChartData(data);

    if (this.props.niche) {
      yMax = yMax * 4;
    } else {
      this.props.updateShopTotalSold(shopName, totalSold);
    }

    return {
      time: {
        timezoneOffset: -7 * 60 // GMT+7 (7 giờ so với GMT, mỗi giờ có 60 phút)
      },
      chart: {
        type: 'column'
      },
      title: {
        text: `<span style="color: black;">${this.props.niche? `SOLD ${this.props.shopTeam.split(",").length} SHOP`: shopName} </span><span style="color: red;">${this.props.niche? `${totalSold} (TỔNG ~${Math.round(totalSold / 0.9)})`: totalSold}</span>`,
        // text: `Đơn: </span><span style="color: red;">${totalSold}</span>`,
        useHTML: true, // Bật tính năng HTML cho title
      },
      xAxis: {
        type: 'datetime', // Đảm bảo rằng trục X là thời gian
        title: {
            // text: 'Giờ'
        },
        labels: {
          formatter: function() {
              // Lấy giờ từ timestamp
              let date = new Date(this.value);
              if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY)
                return date.getHours() + "h";  // Chỉ lấy giờ và thêm "h"
              else if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY) {
                date = new Date(this.value + 12 * 60 * 60 * 1000);
                return date.getHours() + "h";
              } else if (viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS
                || viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS
                || viewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS
              ) {
                return moment(date).format("DD/MM");
              }
          }
        }
      },
      yAxis: {
        // min: 0,
        max: yMax,
        title: {
          text: 'Sold'
        }
      },
      series: [{
        data: data.map((item, index) => ({
          x: item[0],
          y: item[1],
          // color: index === shopSold.length && highlightedIndex ? 'blue' : '#2CAFFE', // Thay đổi màu cột khi nhấp nháy
        })),
        name: chartName
        // name: `<span style="color: black; font-size: 20px; font-weight: bold">${this.state.shopName}`,
        // colorByPoint: true
      }],
      plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                crop: false,
                overflow: 'none'
            }
        }
      },
    }
  }
  

  componentWillReceiveProps(props) {
    var { shopName, soldData, viewMode, hourlySoldData, dailySoldData, shopTeam } = props;

    // console.log("ShopSoldChart - hourlySoldData", hourlySoldData);
    // console.log("ShopSoldChart - dailySoldData", dailySoldData);

    var latestShopSold = {};
    
    if (shopTeam) {
      var total_sold = 0;
      var latestTimestamp = 0;

      var shopNames = shopTeam.split(",");
      shopNames.map(shopName => {
        var currentLatestShopSold = soldData[shopName]? soldData[shopName].latestShopSold: "";
        
        if (currentLatestShopSold) {
          total_sold += currentLatestShopSold.total_sold;

          if (latestTimestamp < currentLatestShopSold.timestamp)
            latestTimestamp = currentLatestShopSold.timestamp;
        }
      });

      latestShopSold = (total_sold > 0)? {
        timestamp: latestTimestamp,
        total_sold
      }: "";
    } else {
      latestShopSold = soldData[shopName]? soldData[shopName].latestShopSold: "";
    }

    console.log("KKK latestShopSold ", shopName, latestShopSold);

    this.setState({ shopName, viewMode, latestShopSold, hourlySoldData, dailySoldData })
  } 

  render() {
    return <Container className="shop-sold-chart">
      <Row>
        <span className="view-shop-listing-sold">
          <FaChartPie 
            onClick={e => this.props.handleShowShopListingSold(this.props.shopName)} 
            title="View listing sold detail"
          />
        </span>
        
        {this.state.hourlySoldData && this.state.hourlySoldData.length > 0 ?
        <HighchartsReact
          highcharts={Highcharts}
          options={this.renderOptions()}
          ref={this.chartRef}
        />: <div class="loading">
        <FerrisWheelSpinner loading={true}　color="#17a2b8" />
      </div>}
      </Row>
      <Row className="mt-2 text-center text-bold">
        {!this.props.niche && this.props.shopTeam && this.props.shopTeam.split(",").map(shopName => <span>{shopName}</span>)}
      </Row>
    </Container>
  }
}

const mapStateToProps = (state) => {
  return {
    soldData: state.shopSoldReducer.soldData
  };
};

export default connect(mapStateToProps, { setFlag, getShopSoldStat, getShopSold, getLatestShopSold })(ShopSoldChart);