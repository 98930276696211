/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as moment from 'moment'
import ListingItem from '../components/ListingItem'
import SoldChart from "../components/SoldChart";
import { 
  getListings,
  addListingsToCollection,
  getListingByIds,
  setFlag, 
  updateListingsHeyData, 
  postListings, 
  getAllTasks,
  getAllCollections,
  addNewCollection,
  toggleListingInCollection,
  getListing30DaysSold
} from '../actions';
// import ScrollToTop from "react-scroll-to-top";
import { getTranslate, LANGUAGE_KEYS } from "../utils";
import Pagination from "../components/Pagination";
import ScrollUpButton from 'react-scroll-up-button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "../configs";
import queryString from 'query-string';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    InputGroup,
    Collapse,
    Modal,
    Table
  } from "react-bootstrap";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { TbFilterCog } from "react-icons/tb";

class Listing extends Component {
  constructor() {
    super();
    this.state = {
      listings: [],
      listingByIds: [],
      tasks: [],
      collections: [],
      newCollectionName: "",

      jobList: [
        "Search Keyword",
        "Search Tag",
        "Listings / Shops",
        "Get Listings Detail",
        "Search By Category",
        "Competitor Analysis"
      ],

      isDownloading: false,
      showModalListingSold: false,
      currentListing: null,
      firstTimeLoading: true,

      totalCount: 0,

      outdateListingIds: [],
      forceUpdateHeyData: false,
      query: this.initQuery,

      isShowTitle: true,
      isShowTags: localStorage.getItem('isShowTags') == 'false'? false : true,
      isShowViews: localStorage.getItem('isShowViews') == 'false'? false : true,
      isShowFavorites: localStorage.getItem('isShowFavorites') == 'false'? false : true,
      isShowFavoriteRate: localStorage.getItem('isShowFavoriteRate') == 'false'? false : true,
      isShowCreated: localStorage.getItem('isShowCreated') == 'false'? false : true,
      isShowCheckHey: localStorage.getItem('isShowCheckHey') == 'false'? false : true,
      showSearchFilterModal: localStorage.getItem('showSearchFilterModal') == 'false'? false : true,
      floatingSearchFilterModal: false //localStorage.getItem('floatingSearchFilterModal') == 'false'? false : true,
    }
  }

  initQuery = {
    itemsPerPage: 24,
    itemOffset: 0,
    currentPage: 1,
    priceMin: 0,
    priceMax: 0,
    isUpdateQuery: true,
    dailyViews: 0,
    sold30DaysCalculate: 0,
    revenue30DaysCalculate: 0,
    sold30DaysCount: 0,
    revenue30DaysCount: 0,
    lastUpdate: 10000,
    sortBy: '-created_at',
    sortOrder: 1,
    favoriteRate: 1,
    taskId: '',
    keyword: '',
    botId: -1,
    jobId: -1,
    dateFrom: moment().subtract(7, 'days').format("YYYY/MM/DD")
  }

  updateUrl = (query) => {
    const { history } = this.props;
    const queryStr = queryString.stringify(query);

    history.push({
      pathname: '/admin/listing',
      search: queryStr,
    });
  }

  updatePage = (itemOffset, newPage) => {
    console.log("updatePage", newPage, this.state.currentPage);

    if (newPage == this.state.currentPage)
        return;

    this.setState({ 
      query: {...this.state.query, itemOffset: itemOffset, currentPage: newPage},
      forceUpdateHeyData: false,
      listing: []
    }, () => {
      this.setState({ isUpdateQuery: false }, () => {
        this.updateUrl(this.state.query)
        // console.log("getListings - updatePage");
        // this.props.getListings(this.state.query);
      })
    })
  }

  handleChangeKeyword= (e) => {
    this.setState({ 
      query: {...this.state.query, keyword: e.target.value }
    })
  }

  handleChangeDailyViews= (e) => {
    this.setState({ 
      query: {...this.state.query, dailyViews: e.target.value }
    })
  }

  handleChangeFavoriteRate= (e) => {
      this.setState({ 
        query: {...this.state.query, favoriteRate: e.target.value }
      })
  }

  handleChangeSold30DaysCalculate= (e) => {
    this.setState({ 
      query: {...this.state.query, sold30DaysCalculate: e.target.value }
    })
  }

  handleChangeRevenue30DaysCalculate= (e) => {
    this.setState({ 
      query: {...this.state.query, revenue30DaysCalculate: e.target.value }
    })
  }

  handleChangeSold30DaysCount= (e) => {
    this.setState({ 
      query: {...this.state.query, sold30DaysCount: e.target.value }
    })
  }

  handleChangeRevenue30DaysCount= (e) => {
    this.setState({ 
      query: {...this.state.query, revenue30DaysCount: e.target.value }
    })
  }

  handleSelectBot = (e) => {
    this.setState({ 
      query: {...this.state.query, botId: e.target.value }
    })
  }

  handleSelectJob = (e) => {
    this.setState({ 
      query: {...this.state.query, jobId: parseInt(e.target.value) }
    })
  }

  handleSelectSort = (e) => {
    this.setState({ 
      query: {...this.state.query, sortBy: e.target.value }
    })
  }

  handleSelectSortOrder = (e) => {
    this.setState({ 
      query: {...this.state.query, sortOrder: parseInt(e.target.value) }
    })
  }

  handleSelectLastUpdate = (e) => {
    this.setState({ 
      query: {...this.state.query, lastUpdate: e.target.value }
    })
  }

  handleSelectTask = (e) => {
    e.preventDefault();
    var taskId = e.target.value;

    var job6Tasks = this.state.tasks.filter(task => task._id == taskId && task.job_id == 6);

    if (job6Tasks.length > 0)
      this.props.getListingByIds([job6Tasks[0].task_content])

    this.setState({ 
      query: {...this.state.query, taskId }
    }, () => {
      this.updateUrl(this.state.query)
      console.log("getListings - handleSelectTask");
      this.props.getListings(this.state.query);
      localStorage.setItem('query', JSON.stringify(this.state.query))
    })
  }

  handleChangePriceMin= (e) => {
    this.setState({ 
      query: {...this.state.query, priceMin: e.target.value }
    })
  }

  handleChangePriceMax= (e) => {
    this.setState({ 
      query: {...this.state.query, priceMax: e.target.value }
    })
  }

  handleChangeDateFrom = (e) => {
    var dateFrom = e.target.value;
    console.log('dateFrom', dateFrom);

    this.setState({ 
      query: {
        ...this.state.query, dateFrom
      }
    });
  }

  handleExportGGSheet = (e) => {
    e.preventDefault();
    let query = { ...this.state.query };
    query.exportGGsheet = true;
    this.props.getListings(query, true);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('handleSubmit');
    this.updateUrl(this.state.query)
    console.log("getListings - handleSubmit");
    this.props.getListings(this.state.query);
    localStorage.setItem('query', JSON.stringify(this.state.query));

    this.closeModalSearchFilter();
  }

  handleToggleShowTags = (e) => {
    this.setState({
      isShowTags: !this.state.isShowTags
    })

    localStorage.setItem('isShowTags', !this.state.isShowTags);
  }

  handleToggleShowViews = (e) => {
    this.setState({
      isShowViews: !this.state.isShowViews
    })

    localStorage.setItem('isShowViews', !this.state.isShowViews);
  }

  handleToggleShowFavorites = (e) => {
    this.setState({
      isShowFavorites: !this.state.isShowFavorites
    })

    localStorage.setItem('isShowFavorites', !this.state.isShowFavorites);
  }

  handleToggleShowFavoriteRate = (e) => {
    this.setState({
      isShowFavoriteRate: !this.state.isShowFavoriteRate
    })

    localStorage.setItem('isShowFavoriteRate', !this.state.isShowFavoriteRate);
  }

  handleToggleShowCreated = (e) => {
    this.setState({
      isShowCreated: !this.state.isShowCreated
    })

    localStorage.setItem('isShowCreated', !this.state.isShowCreated);
  }

  handleToggleShowCheckHey = (e) => {
    this.setState({
      isShowCheckHey: !this.state.isShowCheckHey
    })

    localStorage.setItem('isShowCheckHey', !this.state.isShowCheckHey);
  }

  needUpdateHeyData = (listing) => {
    // "2023-07-30T18:15:22.912Z"
    var lastCheckHey = moment(listing.last_check_hey);
    var today = moment();

    return !lastCheckHey.isSame(today, 'day');
  }

  handleUpdateHeyData = (e) => {
    e.preventDefault();
    console.log('handleUpdateHeyData');
    this.setState({ forceUpdateHeyData: true });
    this.props.updateListingsHeyData(this.state.listings.map(listing => listing.listing_id));
  }

  handleResetSearch = (e) => {
    e.preventDefault();
    this.setState({
      query: this.initQuery
    })
  }

  handleExportCSV = async (e) => {
    e.preventDefault();

    this.setState({ isDownloading: true });
    
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${process.env.REACT_APP_API_URL}/api/listing/export-csv`, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        const blob = xhr.response;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export.csv';
        link.click();
      }

      xhr.send();
      this.setState({ isDownloading: false });
    } catch (error) {
      console.error('Error:', error);
      this.setState({ isDownloading: false });
    }
  }

  componentDidMount() {
      const queryStringFromUrl = window.location.search;
      const urlParams = new URLSearchParams(queryStringFromUrl);
      var itemsPerPage = urlParams.get('itemsPerPage');

      if (itemsPerPage) {
        const queryObject = queryString.parse(queryStringFromUrl);
        
        ['itemsPerPage', 'itemOffset', 'currentPage'].map(key => {
          queryObject[key] = parseInt(queryObject[key])
        })

        queryObject.itemOffset = (queryObject.currentPage - 1) * queryObject.itemsPerPage;
        
        this.setState({
          query: queryObject
        }, () => {
          this.props.getAllTasks();
          console.log("getListings - componentDidMount");
          this.props.getListings(this.state.query);
          this.props.getAllCollections();
        })
      } else {
        this.props.getAllTasks();
        this.props.getAllCollections();

        var query = JSON.parse(localStorage.getItem('query'));

        if (query) {
          var { jobId, taskId } = query;

          this.setState({
            query: {...this.initQuery,
              'priceMin': query['priceMin'] | 0,
              'priceMax': query['priceMax'] | 0,
              'dailyViews': query['dailyViews'] | 0,
              'sold30DaysCalculate': query['sold30DaysCalculate'] | 0,
              'revenue30DaysCalculate': query['revenue30DaysCalculate'] | 0,
              'sold30DaysCount': query['sold30DaysCount'] | 0,
              'revenue30DaysCount': query['revenue30DaysCount'] | 0,
              'lastUpdate': query['lastUpdate'],
              'sortBy': query['sortBy'],
              'favoriteRate': query['favoriteRate'] | 0,
              'keyword': query['keyword'],
              'botId': query['botId'],
              'jobId': query['jobId'] | -1,
              'taskId': query['taskId'],
              'dateFrom': query['dateFrom'] | this.initQuery.dateFrom,
              'sortOrder': query['sortOrder'] | 1
            }
          }, () => {
            if (this.state.query.jobId == 6) {

            }

            console.log("getListings - componentDidMount 2");
            this.props.getListings(this.state.query);
          })
        }
      }
  }

  componentWillReceiveProps(props) {
    var outdateListingIds = props.listings.filter(listing => (this.needUpdateHeyData(listing))).map(listing => listing.listing_id)
    console.log("outdateListingIds", outdateListingIds);
  
    // new data & not update yet
    if (!props.updateHey && outdateListingIds.length > 0) {
      this.props.updateListingsHeyData(outdateListingIds)
      this.setState({ outdateListingIds })
    }

    if (props.tasks.length > this.state.tasks.length) {
      var currentTask = this.state.query.taskId ? props.tasks.filter(task => task._id == this.state.query.taskId)[0]: null;

      if (currentTask && currentTask.job_id == 6)
        this.props.getListingByIds([currentTask.task_content])
    }

    if (!this.props.updateHey && props.updateHey) {
      var listings = this.state.forceUpdateHeyData? props.listings
                        :props.listings.filter(listing => this.state.outdateListingIds.indexOf(listing.listing_id) >= 0)

      this.props.postListings(listings);
    }

    var { listings, listingByIds, tasks, totalCount, updateHey, toastMessage, collections, currentPopupListingId } = props;

    if (listings.length > 0 && this.state.listings.length == 0) {
      const queryStringFromUrl = window.location.search;
      const urlParams = new URLSearchParams(queryStringFromUrl);
      var popup_listing_id = urlParams.get('popup_listing_id');

      if (popup_listing_id) {
        var currentListing = listings.filter(listing => listing.listing_id == popup_listing_id);
        currentListing = currentListing.length > 0? currentListing[0]: null;
  
        if (currentListing)
          this.handleShowModalListingSold(currentListing);
      }
    }

    this.setState({ listings, listingByIds, tasks, totalCount, updateHey, toastMessage, collections, currentPopupListingId })
  }

  handleClick(e) {
    const el = (sel, par) => (par||document).querySelector(sel);
    const elPopup = el("#popup-add-to-collection");
    Object.assign(elPopup.style, {
      display: `none`
    });
  }

  toggleCheckboxCollection(e, listingId, collectionId) {
    e.preventDefault();
    e.stopPropagation();
    console.log('addListingToCollection', listingId, collectionId);

    var listing = this.state.listings.filter(listing => listing.listing_id == listingId)[0];
    this.props.toggleListingInCollection(listing, collectionId);
  }

  handleChangeCollectionName = (e) => {
    e.stopPropagation();
    this.setState({ newCollectionName: e.target.value });
  }

  addNewCollection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.addNewCollection(this.state.newCollectionName);
  }

  renderCheckboxCollections = () => {
    if (!this.state.currentPopupListingId)
      return <></>;

    return <Form>
      {this.state.collections.map(collection => <>
        <Form.Check
        type='checkbox'
        label={collection.name}
        checked={collection.listing_ids && collection.listing_ids.indexOf(this.state.currentPopupListingId) >= 0}
        onClick={e => this.toggleCheckboxCollection(e, this.state.currentPopupListingId, collection._id)}
      />
      </>)}
    </Form>
  }

  hanldeAddAllToCollection = (e) => {
    e.preventDefault();
    var currentTask = this.state.query.taskId ? this.state.tasks.filter(task => task._id == this.state.query.taskId)[0]: {};
    let keyword = currentTask? currentTask.task_content.split(",")[0]: 'all';
    this.props.addListingsToCollection(this.state.query, `Topic "${keyword}"`);
  }

  handleShowModalListingSold = (listing) => {
    this.setState({
      showModalListingSold: true,
      currentListing: listing,
      query: {
        ...this.state.query,
        popup_listing_id: listing.listing_id
      }
    });

    this.props.getListing30DaysSold(listing.listing_id);

    const queryStringFromUrl = window.location.search;
    const urlParams = new URLSearchParams(queryStringFromUrl);
    var itemsPerPage = urlParams.get('itemsPerPage');
    urlParams.set('popup_listing_id', listing.listing_id);

    console.log('urlParams', urlParams.toString(), itemsPerPage)

    var currentURL = window.location.protocol + "//" 
      + window.location.host 
      + window.location.pathname 
      + (itemsPerPage? '?': '?')
      + urlParams.toString();
      
		window.history.pushState('', '', currentURL);
  }

  handleCloseModalListingSold = (e) => {
    this.setState({
      showModalListingSold: false,
      query: {
        ...this.state.query,
        popup_listing_id: ''
      }
    });

    // const queryStringFromUrl = window.location.search;
    // const urlParams = new URLSearchParams(queryStringFromUrl);
    // urlParams.set('popup_listing_id', '');

    // var currentURL = window.location.protocol + "//" 
    //   + window.location.host 
    //   + window.location.pathname 
    //   + (queryStringFromUrl? '': '?')
    //   + urlParams.toString();
      
		// window.history.pushState({ path: currentURL }, '', currentURL);
  }

  handleCopyText(text) {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }

    toast.success(`Copied to clipboard!`, {
      position: "bottom-right",
      autoClose: 500,
      hideProgressBar: true
    });
  }

  renderCustomBadge(style, className, text) {
    return <Badge key={text} pill 
      bg={style}
      className={className}
      onClick={() => this.handleCopyText(text)}
      title="Click to copy"
    >
      {text}
    </Badge>
  }

  showModalSearchFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      showSearchFilterModal: !this.state.showSearchFilterModal
    });

    localStorage.setItem('showSearchFilterModal', !this.state.showSearchFilterModal);
  }

  toggleFloatingSearchFilterModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      floatingSearchFilterModal: !this.state.floatingSearchFilterModal
    });

    localStorage.setItem('floatingSearchFilterModal', !this.state.floatingSearchFilterModal);
  }

  closeModalSearchFilter = () => {
    this.setState({
      showSearchFilterModal: false
    });

    localStorage.setItem('showSearchFilterModal', false);
  }

  renderSearchFilter = () => {
    var tasks = this.state.query.botId >= 0? 
                  this.state.tasks.filter(task => task.bot_id == this.state.query.botId && (task.step >= 3 || task.step >=4 )):
                  this.state.tasks.filter(task => task.step >= 3 || task.step >= 4);

    tasks = this.state.query.jobId >= 0?
              tasks.filter(task => task.job_id == this.state.query.jobId): tasks;

    return <>
            <Row className="">
              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_BOT_ID)}</InputGroup.Text>
                  <Form.Select
                  value={this.state.query.botId}
                  onChange={this.handleSelectBot}
                >
                  <option value="-1">{getTranslate(LANGUAGE_KEYS.TEXT_ALL)}</option>
                  <option value="0">Bot 0</option>
                  <option value="1">Bot 1</option>
                  <option value="2">Bot 2</option>
                  <option value="3">Bot 3</option>
                  <option value="4">Bot 4</option>
                  <option value="5">Bot 5</option>
                  </Form.Select>
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_JOB_ID)}</InputGroup.Text>
                  <Form.Select
                  value={this.state.query.jobId}
                  onChange={this.handleSelectJob}
                >
                  <option value="-1">{getTranslate(LANGUAGE_KEYS.TEXT_ALL)}</option>
                  {this.state.jobList.map((job, idx) => <option value={`${idx+1}`}>{job}</option>)}
                  </Form.Select>
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_TASK)}</InputGroup.Text>
                  <Form.Select
                  value={this.state.query.taskId}
                  onChange={this.handleSelectTask}
                >
                  <option value="">{getTranslate(LANGUAGE_KEYS.TEXT_ALL)}</option>
                  {tasks.map(task => (
                    <option value={task._id}>
                      <p className="text-capitalize">{task.task_content} - {task._id}</p>
                      </option>
                  ))}
                  </Form.Select>
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_FROM)}</InputGroup.Text>
                  <Form.Control type="date" name="dob" placeholder="Date of Birth" 
                  value={this.state.query.dateFrom}
                  onChange={this.handleChangeDateFrom}
                />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SEARCH)}</InputGroup.Text>
                  <Form.Control
                    className='text-red'
                    value={this.state.query.keyword}
                    onChange={this.handleChangeKeyword}
                    placeholder="Enter keyword"
                    aria-label="keyword"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_DAILY_VIEW)} ≥</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.query.dailyViews}
                    onChange={this.handleChangeDailyViews}
                    placeholder="Daily Views"
                    aria-label="daily_view"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_PRICE_MIN)} ≥</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.priceMin}
                    onChange={this.handleChangePriceMin}
                    placeholder=""
                    aria-label="price-min"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_PRICE_MAX)} ≤ </InputGroup.Text>
                  <Form.Control
                    value={this.state.query.priceMax}
                    onChange={this.handleChangePriceMax}
                    placeholder=""
                    aria-label="price-max"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="">
              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SOLD_30_CALCULATE)} ≥</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.sold30DaysCalculate}
                    onChange={this.handleChangeSold30DaysCalculate}
                    placeholder="Sold 30 Days Calculate"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_REVENUE_30_CALCULATE)} ≥</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.revenue30DaysCalculate}
                    onChange={this.handleChangeRevenue30DaysCalculate}
                    placeholder="Revenue 30 Days Calculate"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SOLD_30_COUNT)} ≥</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.sold30DaysCount}
                    onChange={this.handleChangeSold30DaysCount}
                    placeholder="Sold 30 Days Count"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_REVENUE_30_COUNT)} ≥</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.revenue30DaysCount}
                    onChange={this.handleChangeRevenue30DaysCount}
                    placeholder="Revenue 30 Days Count"
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_FAVORITE_RATE)} ≥</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.favoriteRate}
                    onChange={this.handleChangeFavoriteRate}
                    placeholder="Favorite rate"
                    aria-label="daily_view"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_LAST_UPDATE)}</InputGroup.Text>
                  <Form.Select aria-label=""
                    value={this.state.query.lastUpdate}
                    onChange={this.handleSelectLastUpdate}
                >
                  <option value="1000">{getTranslate(LANGUAGE_KEYS.TEXT_ALL)}</option>
                  <option value="1">1 {getTranslate(LANGUAGE_KEYS.TEXT_DAY)}</option>
                  <option value="2">2 {getTranslate(LANGUAGE_KEYS.TEXT_DAYS)}</option>
                  <option value="3">3 {getTranslate(LANGUAGE_KEYS.TEXT_DAYS)}</option>
                  <option value="4">4 {getTranslate(LANGUAGE_KEYS.TEXT_DAYS)}</option>
                  <option value="5">5 {getTranslate(LANGUAGE_KEYS.TEXT_DAYS)}</option>
                  <option value="6">6 {getTranslate(LANGUAGE_KEYS.TEXT_DAYS)}</option>
                  <option value="7">1 {getTranslate(LANGUAGE_KEYS.TEXT_WEEK)}</option>
                  <option value="14">2 {getTranslate(LANGUAGE_KEYS.TEXT_WEEKS)}</option>
                  <option value="21">3 {getTranslate(LANGUAGE_KEYS.TEXT_WEEKS)}</option>
                  <option value="30">1 {getTranslate(LANGUAGE_KEYS.TEXT_MONTH)}</option>
                  </Form.Select>
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SORT_BY)}</InputGroup.Text>
                  <Form.Select
                  value={this.state.query.sortBy}
                  onChange={this.handleSelectSort}
                >
                  <option value="-daily_views">{getTranslate(LANGUAGE_KEYS.TEXT_DAILY_VIEW)}</option>
                  <option value="-views">{getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_VIEWS)}</option>
                  <option value="-sale_30_days_calculate">{getTranslate(LANGUAGE_KEYS.TEXT_SOLD_30_CALCULATE)}</option>
                  <option value="-revenue_30_days_calculate">{getTranslate(LANGUAGE_KEYS.TEXT_REVENUE_30_CALCULATE)}</option>
                  <option value="-sale_30_days_count">{getTranslate(LANGUAGE_KEYS.TEXT_SOLD_30_COUNT)}</option>
                  <option value="-revenue_30_days_count">{getTranslate(LANGUAGE_KEYS.TEXT_REVENUE_30_COUNT)}</option>
                  <option value="-price_usd">{getTranslate(LANGUAGE_KEYS.TEXT_PRICE)}</option>
                  <option value="last_modified_minutes">{getTranslate(LANGUAGE_KEYS.TEXT_LAST_UPDATE)}</option>
                  <option value="-num_favorers">{getTranslate(LANGUAGE_KEYS.TEXT_FAVORITE)}</option>
                  <option value="-favorite_rate">{getTranslate(LANGUAGE_KEYS.TEXT_FAVORITE_RATE)}</option>
                  <option value="original_creation_days">{getTranslate(LANGUAGE_KEYS.TEXT_CREATED_DATE)}</option>
                  <option value="-created_at">{getTranslate(LANGUAGE_KEYS.TEXT_EIT_CREATE)}</option>
                  <option value="-updated_at">{getTranslate(LANGUAGE_KEYS.TEXT_EIT_UPDATE)}</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SORT_ORDER)}</InputGroup.Text>
                  <Form.Select
                  value={this.state.query.sortOrder}
                  onChange={this.handleSelectSortOrder}
                >
                  <option value="1">{getTranslate(LANGUAGE_KEYS.TEXT_DECREASE)}</option>
                  <option value="-1">{getTranslate(LANGUAGE_KEYS.TEXT_INCREASE)}</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>

            <Row className="filter-group">
                <Col md="2">
                  <Form>
                    <Form.Check
                      type='switch'
                      label="Tags/Categories"
                      checked={this.state.isShowTags}
                      onChange={this.handleToggleShowTags}
                    />

                    <Form.Check
                      type='switch'
                      label={getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_VIEWS)}
                      checked={this.state.isShowViews}
                      onChange={this.handleToggleShowViews}
                    />
                  </Form>
                </Col>

                <Col md="2">
                  <Form>
                    <Form.Check
                      type='switch'
                      label={getTranslate(LANGUAGE_KEYS.TEXT_FAVORITE)}
                      checked={this.state.isShowFavorites}
                      onChange={this.handleToggleShowFavorites}
                    />
                    <Form.Check
                      type='switch'
                      label={getTranslate(LANGUAGE_KEYS.TEXT_FAVORITE_RATE)}
                      checked={this.state.isShowFavoriteRate}
                      onChange={this.handleToggleShowFavoriteRate}
                    />
                  </Form>
                </Col>

                <Col md="2">
                  <Form>
                    <Form.Check
                      type='switch'
                      label={getTranslate(LANGUAGE_KEYS.TEXT_CREATED)}
                      checked={this.state.isShowCreated}
                      onChange={this.handleToggleShowCreated}
                    />

                    <Form.Check
                      type='switch'
                      label={getTranslate(LANGUAGE_KEYS.TEXT_LAST_CHECK_HEY)}
                      checked={this.state.isShowCheckHey}
                      onChange={this.handleToggleShowCheckHey}
                    />
                  </Form>
                </Col>

                <Col md="2">
                </Col>

                <Col md="4">
                  {/* <InputGroup className="mb-3"> */}
                  <div class='btn-group-listing'>
                    {/* <Button
                      className="btn-fill pull-right btn-sm"
                      type="submit"
                      variant="warning"
                      onClick={this.handleUpdateHeyData}
                      style={{ "padding": "5px" }}
                    >
                      Update Hey
                    </Button>

                    <Button
                      className="btn-fill pull-right ml-3 btn-sm"
                      type="submit"
                      variant="danger"
                      onClick={this.handleExportCSV}
                      style={{ "padding": "5px" }}
                      disabled={this.state.isDownloading}
                    >
                      {this.state.isDownloading? 'Downloading ...': 'Export CSV'}
                    </Button> */}

                    {/* <Button
                      className="btn-fill pull-right ml-3"
                      type="submit"
                      variant="light"
                      onClick={() => {this.handleResetSearch}}
                      style={{ "padding": "5px" }}
                    >
                      Clear
                    </Button> */}

                    <Button
                      className="btn-fill pull-right ml-3 btn-sm"
                      type="submit"
                      variant="primary"
                      onClick={this.handleSubmit}
                      style={{ "padding": "5px" }}
                    >
                      {getTranslate(LANGUAGE_KEYS.TEXT_SEARCH)}
                    </Button>

                  {this.props.user.username == "anhnv" && <>
                    <Button
                      className="btn-fill pull-right ml-3 btn-sm"
                      type="submit"
                      variant="danger"
                      onClick={this.handleExportGGSheet}
                      style={{ "padding": "5px" }}
                    >
                      Export GoogleSheet
                    </Button>
                    <Button
                      className="btn-fill pull-right ml-3 btn-sm"
                      type="submit"
                      variant="success"
                      onClick={this.hanldeAddAllToCollection}
                      style={{ "padding": "5px" }}
                    >
                      Add to Collection
                    </Button>
                  </>}

                  </div>
                  {/* </InputGroup> */}
                </Col>
            </Row>
    </>
  }

  render() {
    console.log("Render", this.state);
    var tasks = this.state.query.botId >= 0? 
                  this.state.tasks.filter(task => task.bot_id == this.state.query.botId && (task.status >= 3 || task.step >=4 )):
                  this.state.tasks.filter(task => task.status >= 3 || task.step >= 4);

    tasks = this.state.query.jobId >= 0?
              tasks.filter(task => task.job_id == this.state.query.jobId): tasks;
    
    var currentTask = this.state.query.taskId ? tasks.filter(task => task._id == this.state.query.taskId)[0]: null;
    var currentListing = this.state.currentListing;

    var job6Listing = (currentTask && currentTask.job_id == 6 && this.state.listingByIds.length > 0)? this.state.listingByIds[0]: null;

    return (
    <>
      <Container fluid id="listing-page"
        onClick={this.handleClick}
      >
        <Card 
          id="popup-add-to-collection" 
          bg="light"
          border="dark"
          text="dark"
        >
          {/* <Card.Header className="text-center">Header</Card.Header> */}
          <Card.Title className="text-center mt-2 mb-2">{getTranslate(LANGUAGE_KEYS.TEXT_ADD_TO_COLLECTION)}</Card.Title>

          <Card.Body id="collections-list">
            {this.renderCheckboxCollections()}
          </Card.Body>

          <Card.Footer>
            <Form>
              <Form.Control 
                size="sm" 
                type="text" 
                placeholder="Collection Name" 
                id="input-add-new-collection"
                value={this.state.newCollectionName}
                onChange={this.handleChangeCollectionName}
                onClick={e => { e.stopPropagation(); }}
              />
              <Button
                className="btn-fill pull-right btn-sm"
                id="btn-add-new-collection"
                disabled={this.state.newCollectionName == ""}
                type="submit"
                variant="primary"
                onClick={this.addNewCollection}
              >
                {getTranslate(LANGUAGE_KEYS.TEXT_ADD)}
              </Button>
            </Form>
          </Card.Footer>
        </Card>
        
        <ScrollUpButton />

        {this.state.floatingSearchFilterModal && <>
          <div id="search-filter-icon" className="cursor-pointer" title="Search & Filter">
            <TbFilterCog onClick={ this.showModalSearchFilter }/>
          </div>
          <Modal size="xl" className="modal-search-filter"
            show={this.state.showSearchFilterModal}
            onHide={this.closeModalSearchFilter}
          >
            <Modal.Header closeButton>
              <strong>{getTranslate(LANGUAGE_KEYS.TEXT_SEARCH_AND_FILTER)}</strong>
            </Modal.Header>
            <Modal.Body>
              {this.renderSearchFilter()}
            </Modal.Body>
          </Modal>
        </>}

        {!this.state.floatingSearchFilterModal && this.renderSearchFilter()}

        {job6Listing &&
        <Card>
          <Card.Header>
            <strong>{getTranslate(LANGUAGE_KEYS.TEXT_COMPETITOR_ANALYSIS)}</strong>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md="2">
                <a href={`https://etsy.com/listing/${job6Listing.listing_id}`} target="_blank" title='View this listing on Etsy'>
                  <Card.Img variant="top" src={job6Listing.img_cover_url} />
                </a>
              </Col>
              <Col md="3">
                <Table>
                  <tbody>
                    <tr>
                      <td></td>
                      <td className="value">
                        <Badge pill bg="danger">
                        {job6Listing.currency_symbol?job6Listing.currency_symbol:'$'}
                        {job6Listing.price_usd?`${job6Listing.price_usd.toFixed(2)}`: '~'}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td>{getTranslate(LANGUAGE_KEYS.TEXT_DAILY_VIEW)}</td>
                      <td className="value">
                        <Badge pill bg="danger">
                          {job6Listing.daily_views}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      {getTranslate(LANGUAGE_KEYS.TEXT_CREATED)}
                      </td>
                      <td className="value">
                        <Badge pill bg="info">
                        {job6Listing.original_creation}
                        </Badge>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col md="3">
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        {getTranslate(LANGUAGE_KEYS.TEXT_FAVORITE_RATE)}
                      </td>
                      <td className="value">
                        <Badge pill bg="danger">
                        { job6Listing.favorite_rate? job6Listing.favorite_rate
                        : '~' }
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td>{getTranslate(LANGUAGE_KEYS.TEXT_SOLD_30_CALCULATE)}</td>
                      <td className="value">
                        <Badge pill bg="info">
                          {job6Listing.sale_30_days_calculate}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td>{getTranslate(LANGUAGE_KEYS.TEXT_SOLD_30_COUNT)}</td>
                      <td className="value">
                        <Badge pill bg="success"
                          title="View 30 days sold chart"
                          className="cursor-pointer"
                          onClick={this.handleDisplaySold30Days}
                        >
                          {job6Listing.public_sold? (job6Listing.sale_30_days_count != null && job6Listing.sale_30_days_count >= 0? job6Listing.sale_30_days_count: 'Loading ...')
                            :'Not public'}
                        </Badge>
                      </td>
                    </tr>               
                  </tbody>
                </Table>
              </Col>

              <Col md="4">
                <Table>
                  <tbody>
                    <tr>
                      <td>{getTranslate(LANGUAGE_KEYS.TEXT_LAST_UPDATE)}</td>
                      <td className="value">
                        <Badge pill bg="danger">
                          {job6Listing.last_modified}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td>{getTranslate(LANGUAGE_KEYS.TEXT_REVENUE_30_CALCULATE)}</td>
                      <td className="value">
                        <Badge pill bg="info">
                        ${job6Listing.revenue_30_days_calculate? job6Listing.revenue_30_days_calculate.toFixed(2): 0}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td>{getTranslate(LANGUAGE_KEYS.TEXT_REVENUE_30_COUNT)}</td>
                      <td className="value">
                        <Badge pill bg="success">
                          ${job6Listing.revenue_30_days_count? job6Listing.revenue_30_days_count.toFixed(2): 0}
                        </Badge>
                      </td>
                    </tr>                  
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="">
              <Col md="2">
                <div className='text-white listing-tag'>
                  <a href={`https://etsy.com/shop/${job6Listing.shop_name}`} target='_blank' title={getTranslate(LANGUAGE_KEYS.TEXT_VISIT_SHOP_X, [job6Listing.shop_name])}>
                    <Badge pill bg="dark">{job6Listing.shop_name}</Badge>
                  </a>
                </div>
              </Col>
              <Col md="2">
                <Badge pill bg="danger">{job6Listing.listing_id}</Badge>
              </Col>
              <Col md="8">
                {job6Listing.tags.split(",").map(tag => <Badge pill bg="primary">{tag}</Badge>)}
              </Col>

            </Row>
            {/* <Row>
              <SoldChart listing={job6Listing} />
            </Row> */}
          </Card.Body>
        </Card>}

        <Row>
          <h5>
            <b>{getTranslate(LANGUAGE_KEYS.TEXT_FOUND_X_LISTINGS, [<span className='text-danger'>{this.state.totalCount}</span>])}:</b>
            {/* <Badge pill bg="primary" className="ml-3 cursor-pointer"
              onClick={this.toggleFloatingSearchFilterModal}
            >
              {this.state.floatingSearchFilterModal? 'Fixed Search Filter': 'Floating Search Filter'}
            </Badge> */}
          </h5>
        </Row>

        <Row>
            {(this.state.listings.length > 0)?
            this.state.listings
            .map(listing => <Col md="3">
                <ListingItem 
                  key={listing.listing_id} 
                  handleShowModalListingSold={this.handleShowModalListingSold}
                  listing={listing} 
                  isShowTags={this.state.isShowTags}
                  isShowViews={this.state.isShowViews} 
                  isShowFavorites={this.state.isShowFavorites}
                  isShowFavoriteRate={this.state.isShowFavoriteRate}
                  isShowCreated={this.state.isShowCreated} 
                  isShowCheckHey={this.state.isShowCheckHey} 
                  isShowTitle={this.state.isShowTitle} 
                />
            </Col>)
            :<div class="loading">
                <FerrisWheelSpinner loading={true}　color="#17a2b8" />
            </div>}
        </Row>
      </Container>
      <Pagination totalItems = {this.state.totalCount} itemsPerPage = {this.state.query.itemsPerPage} itemOffset = {this.state.query.itemOffset} updatePage={this.updatePage}/>
      {currentTask && localStorage.getItem('dev_mode') == 'true' && <>
        <div>{currentTask._id} : {currentTask.task_content} - by Bot {currentTask.bot_id} on {currentTask.finish_time}</div>
      </>}
      {/* {this.state.toastMessage && <>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
          {this.state.toastMessage}
        <ToastContainer />
      </>} */}
      <ToastContainer
        position="bottom-right"
        autoClose={500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

      <Modal size="xl" className="modal-listing-sold"
        show={this.state.showModalListingSold} 
        onHide={this.handleCloseModalListingSold}
        >
        <Modal.Header closeButton>
          {currentListing && <>
            <a href={`https://etsy.com/listing/${currentListing.listing_id}`} target="_blank" title='View this listing on Etsy'>
              {currentListing.title}
            </a>
            {this.renderCustomBadge("danger", "ml-2 cursor-pointer", currentListing.listing_id)}
          </>}
        </Modal.Header>
        <Modal.Body>
          {currentListing && <Row>
            <Col md="2">
              <a href={`https://etsy.com/listing/${currentListing.listing_id}`} target="_blank" title='View this listing on Etsy'>
                <Card.Img variant="top" src={currentListing.img_cover_url} />
              </a>
            </Col>
            <Col md="3">
              <Table>
                <tbody>
                  <tr>
                    <td>Price</td>
                    <td className="value">
                      <Badge pill bg="danger">
                      {currentListing.currency_symbol?currentListing.currency_symbol:'$'}
                      {currentListing.price_usd?`${currentListing.price_usd.toFixed(2)}`: '~'}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Daily views</td>
                    <td className="value">
                      <Badge pill bg="danger">
                        {currentListing.daily_views}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Created
                    </td>
                    <td className="value">
                      <Badge pill bg="info">
                      {currentListing.original_creation}
                      </Badge>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col md="3">
              <Table>
                <tbody>
                  <tr>
                    <td>
                      Favorite Rate
                    </td>
                    <td className="value">
                      <Badge pill bg="danger">
                      { currentListing.favorite_rate? currentListing.favorite_rate
                      : '~' }
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Sold-30 calculate</td>
                    <td className="value">
                      <Badge pill bg="info">
                        {currentListing.sale_30_days_calculate}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Sold-30 count</td>
                    <td className="value">
                      <Badge pill bg="success"
                        title="View 30 days sold chart"
                        className="cursor-pointer"
                        onClick={this.handleDisplaySold30Days}
                      >
                        {currentListing.public_sold? (currentListing.sale_30_days_count != null && currentListing.sale_30_days_count >= 0? currentListing.sale_30_days_count: 'Loading ...')
                          :'Not public'}
                      </Badge>
                    </td>
                  </tr>               
                </tbody>
              </Table>
            </Col>

            <Col md="4">
              <Table>
                <tbody>
                  <tr>
                    <td>Last update</td>
                    <td className="value">
                      <Badge pill bg="danger">
                        {currentListing.last_modified}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Revenue-30 calculate</td>
                    <td className="value">
                      <Badge pill bg="info">
                      ${currentListing.revenue_30_days_calculate? currentListing.revenue_30_days_calculate.toFixed(2): 0}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Revenue-30 count</td>
                    <td className="value">
                      <Badge pill bg="success">
                        ${currentListing.revenue_30_days_count? currentListing.revenue_30_days_count.toFixed(2): 0}
                      </Badge>
                    </td>
                  </tr>                  
                </tbody>
              </Table>
            </Col>
          </Row>}

          <Row>
            <SoldChart listing={currentListing} />
          </Row>
        </Modal.Body>
      </Modal>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authenReducer.user,
    listings: state.listingReducer.listings,
    listingByIds: state.listingReducer.listingByIds,
    tasks: state.taskReducer.tasks,
    collections: state.collectionReducer.collections,
    totalCount: state.listingReducer.totalCount,
    updateHey: state.listingReducer.updateHey,
    currentPopupListingId: state.miscReducer.flag[CONFIGS.FLAGS.CURRENT_POPUP_LISTING_ID]
  }
}

export default connect(mapStateToProps, { 
  getListings,
  addListingsToCollection,
  getListingByIds,
  setFlag, 
  updateListingsHeyData, 
  postListings, 
  getAllTasks,
  getAllCollections,
  addNewCollection,
  toggleListingInCollection,
  getListing30DaysSold
})(Listing);