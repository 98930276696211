/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as moment from 'moment'
import CollectionItem from '../components/CollectionItem'
import {
  getAllCollections,
  updateCollection
} from '../actions';
// import ScrollToTop from "react-scroll-to-top";
import Pagination from "../components/Pagination";
import ScrollUpButton from 'react-scroll-up-button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "../configs";
import queryString from 'query-string';
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";

class Collection extends Component {
  constructor() {
    super();
    this.state = {
      collections: [],
      showModalEditCollection: false,
      currentCollection: null,
      sortBy: "name",
      sortOrder: 1,
      keyword: ""
    }
  }

  handleShowPopupEditCollection = (collection) => {
    this.setState({
      showModalEditCollection: true,
      currentCollection: collection
    })
  }

  componentDidMount() {
    // Update 1 
    this.props.getAllCollections();
  }

  componentWillReceiveProps(props) {
    var { collections } = props;
    this.setState({ collections })
  }

  handleEditCollectionName = (e) => {
    e.preventDefault();

    this.setState({
      currentCollection: {
        ...this.state.currentCollection,
        name: e.target.value
      }
    })
  }

  handleEditCollectionDescription = (e) => {
    e.preventDefault();

    this.setState({
      currentCollection: {
        ...this.state.currentCollection,
        description: e.target.value
      }
    })
  }

  saveCollection = (e) => {
    e.preventDefault();
    this.props.updateCollection(this.state.currentCollection);

    toast.success('Save collection successfully');
    this.setState({ showModalEditCollection: false });
  }

  deleteCollection = (e) => {
    e.preventDefault();
    var currentCollection = this.state.currentCollection;
    currentCollection.status = -1;

    this.props.updateCollection(this.state.currentCollection);

    toast.success('Delete collection successfully');
    this.setState({ showModalEditCollection: false });
  }

  handleChangeKeyword = (e) => {
    this.setState({ keyword: e.target.value });
  }

  handleSelectSortBy = (e) => {
    this.setState({ sortBy: e.target.value });
  }

  handleSelectSortOrder = (e) => {
    this.setState({ sortOrder: parseInt(e.target.value) });
  }

  render() {
    let { keyword, sortBy, sortOrder } = this.state;

    let generatedCollections = this.state.collections.filter(collection => collection.type == CONFIGS.COLLECTION_TYPES.GENERATED).sort((a, b) => sortOrder * a[sortBy].localeCompare(b[sortBy]));

    let shopCollections = generatedCollections.filter(collection => collection.name.includes("Shop"));
    let topicCollections = generatedCollections.filter(collection => collection.name.includes("Topic"));

    let userCollections = this.state.collections.filter(collection => collection.type != CONFIGS.COLLECTION_TYPES.GENERATED).sort((a, b) => sortOrder * a[sortBy].localeCompare(b[sortBy]));

    if (keyword) {
      let keywordLowerCase = keyword.toLowerCase();
      shopCollections = shopCollections.filter(collection => collection.name.toLowerCase().includes(keywordLowerCase));
      topicCollections = topicCollections.filter(collection => collection.name.toLowerCase().includes(keywordLowerCase));
      userCollections = userCollections.filter(collection => collection.name.toLowerCase().includes(keywordLowerCase));
    }

    return (
      <>
        <Container fluid id="collection-page">
          <Row className="mb-4">
            <Col md="6">
              <InputGroup>
                <InputGroup.Text>Search</InputGroup.Text>
                <Form.Control
                  className='text-red'
                  value={keyword}
                  onChange={this.handleChangeKeyword}
                  placeholder="Enter keyword"
                  aria-label="keyword"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup>
                <InputGroup.Text>Sort by</InputGroup.Text>
                <Form.Select aria-label=""
                  value={sortBy}
                  onChange={this.handleSelectSortBy}
                >
                  <option value="_id">Date</option>
                  <option value="name">Name</option>
                </Form.Select>
              </InputGroup>
            </Col>
            
            <Col md="3">
              <InputGroup>
                <InputGroup.Text>Sort order</InputGroup.Text>
                <Form.Select aria-label=""
                  value={sortOrder}
                  onChange={this.handleSelectSortOrder}
                >
                  <option value="1">Decrease</option>
                  <option value="-1">Increase</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col md="3">
              {this.state.favorite &&
                <CollectionItem
                  key={this.state.favorite._id}
                  collection={this.state.favorite}
                  favorite={true}
                />}
            </Col>
          </Row>

          <Row>
            <h4>Shop</h4>
            {(shopCollections.length > 0) ?
              shopCollections
                .map(collection => <Col md="2">
                  <CollectionItem
                    key={collection._id}
                    collection={collection}
                    handleShowPopupEditCollection={this.handleShowPopupEditCollection}
                  />
                </Col>)
              : <div>
                <h5 className="text-center">Chưa có collection nào !</h5>
              </div>
            }
          </Row>

          <Row>
            <h4>Topic</h4>
            {(topicCollections.length > 0) ?
              topicCollections
                .map(collection => <Col md="2">
                  <CollectionItem
                    key={collection._id}
                    collection={collection}
                    handleShowPopupEditCollection={this.handleShowPopupEditCollection}
                  />
                </Col>)
              : <div>
                <h5 className="text-center">Chưa có collection nào !</h5>
              </div>
            }
          </Row>

          <Row>
            <h4>Người dùng</h4>
            {(userCollections.length > 0) ?
              userCollections
                .map(collection => <Col md="2">
                  <CollectionItem
                    key={collection._id}
                    collection={collection}
                    handleShowPopupEditCollection={this.handleShowPopupEditCollection}
                  />
                </Col>)
              : <div class="loading">
                <FerrisWheelSpinner loading={true} color="#17a2b8" />
              </div>}
          </Row>
        </Container>

        <Modal size="l" className="modal-edit-collection"
          show={this.state.showModalEditCollection}
          onHide={(e) => this.setState({ showModalEditCollection: false })}
        >
          <Modal.Header closeButton>
            <h4 className="label-edit-collection">Edit Collection</h4>
            <hr />
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter Collection Name"
                  value={this.state.currentCollection ? this.state.currentCollection.name : ''}
                  onChange={this.handleEditCollectionName}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={this.state.currentCollection ? this.state.currentCollection.description : ''}
                  onChange={this.handleEditCollectionDescription}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-fill pull-right btn-sm"
              id="btn-save-collection"
              type="submit"
              variant="danger"
              onClick={this.deleteCollection}
            >
              Delete
            </Button>

            <Button
              className="btn-fill pull-right btn-sm ml-2"
              id="btn-save-collection"
              type="submit"
              variant="primary"
              onClick={this.saveCollection}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collections: state.collectionReducer.collections
  }
}

export default connect(mapStateToProps, {
  getAllCollections,
  updateCollection
})(Collection);