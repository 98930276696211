import { TYPES } from "./types";
import { shopSoldService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getShopSoldStat = (query) => async (dispatch) => {

  try {
    const result = await shopSoldService.getShopSoldStat(query);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_SHOP_SOLD_STAT_SUCCESS,
        payload: {data, shopName: query.shopName }
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_SHOP_SOLD_STAT_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getShopSoldStatGroup = (query) => async (dispatch) => {

  try {
    const result = await shopSoldService.getShopSoldStatGroup(query);
    const data = result.data.data.result;
    
    if (data) {
      // fix duplicate

      var fixedData = [];


      dispatch({
        type: TYPES.ACTION_GET_SHOP_SOLD_STAT_GROUP_SUCCESS,
        payload: { data, type: query.type }
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_SHOP_SOLD_STAT_GROUP_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getShopSold = (query) => async (dispatch) => {
  try {
    const result = await shopSoldService.getShopSold(query);
    const data = result.data.data;

    console.log("query", query, data);
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_SHOP_SOLD_SUCCESS,
        payload: {data, shopName: query.shopName}
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_SHOP_SOLD_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getLatestShopSold = (shopName) => async (dispatch) => {

  try {
    const result = await shopSoldService.getShopSold({shopName, limit: 1, sortBy: "-timestamp"});
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LATEST_SHOP_SOLD_SUCCESS,
        payload: {data, shopName}
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LATEST_SHOP_SOLD_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const shopSoldAction = {
  getShopSoldStat,
  getShopSoldStatGroup,
  getShopSold,
  getLatestShopSold
};
