export const _roundNumber = (x, n) => {
    return Math.round(x * Math.pow(10, n)) / Math.pow(10, n);
}

export const NullOrEmpty = (x) => {
    return x == null || x === "";
}

export const  _formatCurrency = (amount) => {
    // Thêm dấu phân cách hàng nghìn và "đ"
    return (
      amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' đ'
    );
}
  
    