/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { setFlag, toggleListingInCollection } from '../../actions';
import { CONFIGS } from '../../configs';
import { shortenText, _roundNumber } from "../../utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table
  } from "react-bootstrap";

import { BsFillSuitHeartFill, BsFillBookmarkPlusFill } from "react-icons/bs";
import { AiOutlineBarChart, AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import moment from "moment";
// https://react-icons.github.io/react-icons/icons?name=bs

class ListingItem extends Component {
  constructor() {
    super();
    this.state = {
      favorite: null
    };
  }


  componentDidMount() {
  }

  showPopupAddToCollection = (evt) => {
    evt.stopPropagation();
    this.props.setFlag(CONFIGS.FLAGS.CURRENT_POPUP_LISTING_ID, this.props.listing.listing_id.toString());
    // const el = (sel, par) => (par||document).querySelector(sel);
    // const elListingPage = el("#listing-page");
    // const elPopup = el("#popup-add-to-collection");

    const elListing = document.getElementById("listing-page");
    var clientRect = elListing.getBoundingClientRect();
    var clientX = clientRect.left;
    var clientY = clientRect.top;
    var widthListing = clientRect.width;

    const elPopup = document.getElementById("popup-add-to-collection");
    var clientRectPopup = elPopup.getBoundingClientRect();
    var widthPopup = clientRectPopup.width;

    console.log('evt', evt, clientX, clientY);
    var dx = 30;
    var dy = 30;

    var left = Math.min(evt.clientX - clientX + dx);

    if (left + widthPopup > widthListing)
      left = left - widthPopup - dx;

    var right = evt.clientY - clientY + dy;

    elPopup.setAttribute('data-listing-id', this.props.listing.listing_id);

    Object.assign(elPopup.style, {
      left: `${left}px`,
      top: `${right}px`,
      display: `block`,
    });
  }

  componentWillReceiveProps(props) {
    var { favorite } = props;

    this.setState({ favorite });
  }

  handleCopyText(text) {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }

    toast.success(`Copied to clipboard!`, {
      position: "bottom-right",
      autoClose: 500,
      hideProgressBar: true
    });
  }

  renderCustomBadge(style, className, text) {
    return <Badge key={text} pill 
      bg={style}
      className={className}
      onClick={() => this.handleCopyText(text)}
      title="Click to copy"
    >
      {text}
    </Badge>
  }

  handleDisplaySold30Days = (e) => {
    e.preventDefault();
    this.props.handleShowModalListingSold(this.props.listing);
  }

  toggleCheckboxCollection = (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("favorite", this.props.favorite, this.state.favorite);

    this.props.toggleListingInCollection(this.props.listing, this.props.favorite._id);
  }

  renderFavorite = () => {
    var isFavorited = false;

    if (this.props.favorite){
      isFavorited = this.props.favorite.listing_ids.split(',').indexOf(this.props.listing.listing_id.toString()) >= 0;
    }

    if (isFavorited)
      return <AiFillHeart 
        className="btn-favorite-active" 
        title="Remove Favorite"
        onClick={this.toggleCheckboxCollection}
      />;

    return <AiOutlineHeart 
      className="btn-favorite" 
      title="Favorite"
      onClick={this.toggleCheckboxCollection}
    />;  
  }

  renderCategories = (categories) => {
    var categoriesStr = categories.split(",").map(category => category.toLowerCase().trim().replaceAll("&", "-and-").replaceAll(" ","-").replaceAll("--", "-")).join('/');

    return <a href={`https://etsy.com/c/${categoriesStr}`} target="_blank" title='View categories'>
      {categoriesStr}
    </a>
  }

  render() {
    var listing = this.props.listing;

    return (
      <Card className='listing-item'>
        <a href={`https://etsy.com/listing/${listing.listing_id}`} target="_blank" title='View this listing on Etsy'>
          <Card.Img variant="top" src={listing.img_cover_url} />
        </a>

        <BsFillBookmarkPlusFill 
          className="btn-add-to-collection" 
          title="Add to Collection"
          onClick={this.showPopupAddToCollection}
        />

        {this.renderFavorite()}
        
        <Card.Body>
          { this.props.isShowTitle && <Card.Title classNames="h5">
            {shortenText(listing.title, 90)}
          </Card.Title> }
          <Table>
            <tbody>
              <tr>
                <td>Price</td>
                <td className="value">
                  <Badge pill bg="danger">
                  {listing.currency_symbol?listing.currency_symbol:'$'}
                  {listing.price_usd?`${listing.price_usd.toFixed(2)}`: '~'}
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>Daily views</td>
                <td className="value">
                  <Badge pill bg="danger">
                    {listing.daily_views}
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>Sold-30 calculate</td>
                <td className="value">
                  <Badge pill bg="info">
                    {listing.sale_30_days_calculate.toFixed(2)}
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>Revenue-30 calculate</td>
                <td className="value">
                  <Badge pill bg="info">
                  ${listing.revenue_30_days_calculate? listing.revenue_30_days_calculate.toFixed(2): 0}
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>Sold-30 count</td>
                <td className="value">
                  <Badge pill bg={`${listing.public_sold? 'success': 'danger'}`}
                    title="View 30 days sold chart"
                    className="cursor-pointer"
                    onClick={this.handleDisplaySold30Days}
                  >
                    {listing.public_sold? (listing.sale_30_days_count != null && listing.sale_30_days_count >= 0 ? 
                      <>{listing.sale_30_days_count}
                        <span className="custom-icon ml-1"><AiOutlineBarChart /></span>
                      </>: '-')
                      :'Not public'}
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>Revenue-30 count</td>
                <td className="value">
                  <Badge pill bg={`${listing.public_sold? 'success': 'danger'}`}>
                    {(listing.sale_30_days_count != null && listing.sale_30_days_count >= 0) ? '$' + listing.revenue_30_days_count.toFixed(2): '-'}
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>Shipping Fee</td>
                <td className="value">
                  <Badge pill bg="danger">
                    {listing.free_shipping? 'Free': listing.shipping_fee}
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>Last update</td>
                <td className="value">
                  <Badge pill bg="danger">
                    {listing.last_modified}
                  </Badge>
                </td>
              </tr>

              {this.props.isShowViews && <>
                <tr>
                  <td>
                    View
                  </td>
                  <td className="value">
                    <Badge pill bg="danger">
                    {listing.views}
                    </Badge>
                  </td>
                </tr>
              </>}
              
              {this.props.isShowFavorites && <>
                <tr>
                  <td>
                    Favorite <BsFillSuitHeartFill />
                  </td>
                  <td className="value">
                    <Badge pill bg="danger">
                    {listing.num_favorers}
                    </Badge>
                  </td>
                </tr>
              </>}
              
              {this.props.isShowFavoriteRate && <>
                <tr>
                  <td>
                    Favorite Rate
                  </td>
                  <td className="value">
                    <Badge pill bg="danger">
                    { listing.favorite_rate? listing.favorite_rate
                    : '~' }
                    </Badge>
                  </td>
                </tr>
              </>}

              {this.props.isShowCreated && <>
                <tr>
                  <td>
                    Created
                  </td>
                  <td className="value">
                    <Badge pill bg="info">
                    {listing.original_creation}
                    </Badge>
                  </td>
                </tr>
              </>}  
              
              {this.props.isShowCheckHey && <>
                <tr>
                  <td>
                    Check Hey
                  </td>
                  <td className="value">
                    <Badge pill bg="info">
                      {moment(listing.last_check_hey).fromNow()}
                    </Badge>
                  </td>
                </tr>
              </>}
              
            </tbody>
          </Table>
            {/* <Card.Title as="h5">{listing.title}</Card.Title> */}
            <div>
              <a className='text-white listing-tag' href={`https://etsy.com/shop/${listing.shop_name}`} target='_blank' title={`Visit shop ${listing.shop_name}`}>
                <Badge pill bg="dark">{listing.shop_name}</Badge>
              </a>
            </div>      
          {this.props.isShowTags &&
          <>
            {this.renderCustomBadge("danger", "", listing.task_id)}
            {this.renderCustomBadge("danger", "", listing.listing_id)}

            {this.renderCategories(listing.categories)}
            <div className='text-white listing-tag'>
              {/* {listing.tags.split(",").map(tag => <Badge key={tag} pill bg="info">
                {tag}
              </Badge>)} */}
              {listing.tags.split(",").map(tag => <>
                <a href={`https://etsy.com/market/${tag.trim().replaceAll(" ", "_")}`} target='_blank' title={`Visit shop ${listing.shop_name}`}>
                  <Badge pill bg="info">{tag}</Badge>
                </a>
              </>
                // this.renderCustomBadge("info", "", tag)
              )}
            </div>
          </>}
              
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    favorite: state.collectionReducer.favorite
  };
};

export default connect(mapStateToProps, { setFlag, toggleListingInCollection })(ListingItem);