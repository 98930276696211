import { CONFIGS } from "configs";
import { TYPES } from "../actions";

const initialState = {
  soldData: {},
  hourlySoldData: [],
  dailySoldData: [],
  latestShopSold: "",
  currentShopSoldData: []
};

export const shopSoldReducer = (state = initialState, action) => {
  switch (action.type) {

    case TYPES.ACTION_GET_SHOP_SOLD_STAT_SUCCESS:
      var shopName = action.payload.shopName;        

      return { ...state, 
        soldData: {...state.soldData, 
          [shopName]: {
            ...state.soldData[shopName],
            shopSold: action.payload.data.result,
          }
        }
      };

    case TYPES.ACTION_GET_SHOP_SOLD_STAT_GROUP_SUCCESS:
      var type = action.payload.type;
      
      if (type == 'HOURLY' || type == 'TEAM_HOURLY') {
        return { ...state,
          hourlySoldData: action.payload.data }
      } else if (type == 'DAILY' || type == 'TEAM_DAILY') {
        return { ...state,
          dailySoldData: action.payload.data }
      }

    case TYPES.ACTION_GET_SHOP_SOLD_SUCCESS:
      var shopName = action.payload.shopName;        

      return { ...state, 
        currentShopSoldData: action.payload.data.result
      };

    case TYPES.ACTION_GET_LATEST_SHOP_SOLD_SUCCESS:
      var shopName = action.payload.shopName;        

      return { ...state, 
        soldData: {...state.soldData, 
          [shopName]: {
            ...state.soldData[shopName],
            latestShopSold: action.payload.data.result.length > 0?
            action.payload.data.result[0]: ""
          }
        }
      };

    case TYPES.ACTION_GET_SHOPS_FAIL:
      return { ...state, shopSold: [] };
      
    default:
      return state;
  }
};
