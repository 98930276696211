/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as moment from 'moment'
import ShopSoldChart from '../views/ShopSoldChart'
import { getShopSoldStatGroup, getShopSold, getListingByIds } from "../actions"
import { CONFIGS } from '../configs';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";
import { basename } from "path";
import { _roundNumber } from "utils";

class ShopSold extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: new Date(),
      totalTopShopSoldPercentage: 0.9,
      selectedViewMode: CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY,
      showModalListingSold: false,
      currentListingSoldArray: [],
      hourlySoldData: [],
      dailySoldData: [],
      teamHourlySoldData: {},
      teamDailySoldData: {},
      driver1Status: 1,
      driver2Status: 1,
      highlightTitle: true
    }

    this.TOP2TeamChartData = {
      "ANT": [],
      "VĂN": []
    };

    this.shopNames = [
      "ResinEpic",
      "GiftSymphony",

      "Hirosart",
      "ResinLampMadebyRV",

      "ResinArtisanStudio",
      "PhamCF",

      "ResinMion",
      "Musidee",
      "KeycapEmpire",
      "WoodAndResinVN",
      "MiyCraft",
      "CraftGiftsLJ",
      "ResinArtYPCShop",
      "WoodResinPhoneCases",
      "EgoartByVan",
      "MishelayDesigns",
      "BBDecorDesign",
      "handmyme",
      "CustomLampbyCong",
      "NightLightResin",
      "FrozenInOceanLamp",
      "ResinLightGifts",
      "KeizworkingVN",
      "PhuHungResin",
      "LayeredIllusions",
      "EpoxyMiniStore",
      "oyowooaDIY",
      "RESINONLY",
      "WoodnotionEQ",
      "VietResinLeeArt",
      // "StackArtStore",
      "Resin4Gift",
      "LucieWoodCraft"
    ];

    this.shopTeams = {
      "RESIN": this.shopNames.join(","),
      "ANT": "ResinEpic,GiftSymphony,LucieWoodCraft",
      // "THẮNG": "ResinLampMadebyRV,ResinMion,CustomLampbyCong,NightLightResin",
      "VĂN": "Hirosart,EgoartByVan,PhamCF",
      // "LƯƠN": "ResinArtisanStudio"
    }
  }

  async checkErankDriversStatus() {
    console.log("@@@ checkErankDriversStatus start");

    // try {
      this.setState({ loading: true }); // Bắt đầu loading
      const response1 = await fetch(`${process.env.REACT_APP_API_URL}/api/json-data/get?key=ERANK_LISTING_DRIVER_1`); // Thay <API_URL> bằng URL API của bạn
      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/api/json-data/get?key=ERANK_LISTING_DRIVER_2`); // Thay <API_URL> bằng URL API của bạn

      if (!response1.ok || !response2.ok) {
        throw new Error('Failed to fetch data');
      }
      const result1 = await response1.json();
      const result2 = await response2.json();
      console.log("@@@ checkErankDriversStatus", result1)
      this.setState({ 
        driver1Status: result1.data.value.status, 
        driver2Status: result2.data.value.status, 
        loading: false }); // Cập nhật state
    // } catch (error) {
    //   this.setState({ error: error.message, loading: false }); // Cập nhật lỗi nếu có
    // }
  }


  fetchData() {
    // HOURLY
    // const today = new Date();

    // today.setHours(0, 0, 0, 0);
    // var timeMin = today.getTime() / 1000 - 1; // 23:59:59 yesterday

    const time4DaysAgo = moment().subtract(2, 'days').endOf('day');
    // // BLACK FRIDAY
    // const time4DaysAgo = moment().subtract(1, 'days').set({ hour: 11, minute: 0, second: 0 })
    var timeMin = time4DaysAgo.unix();

    // today.setHours(23, 59, 59, 0);
    // var timeMax = today.getTime() / 1000;
    var timeMax = moment().endOf('day').unix();

    this.props.getShopSoldStatGroup({
      shopList: this.shopNames.join("@"),
      type: 'HOURLY',
      sortBy: "timestamp",
      timeMin, timeMax
    });

    // DAILY
    timeMin = moment().subtract(91, 'days').unix(); // max 90 day
    timeMax = moment().unix();

    this.props.getShopSoldStatGroup({
      shopList: this.shopNames.join("@"),
      type: 'DAILY',
      sortBy: "timestamp",
      timeMin, timeMax
    });

  }

  componentDidMount() {
    this.fetchData();
    this.checkErankDriversStatus();

    this.interval = setInterval(() => {
      if (window.location.pathname === '/current-route') {
        // Reload trang nếu đang ở đúng route của component hiện tại
        window.location.reload();
      }
    }, 5 * 60 * 1000); // 5 phút

    this.intervalId = setInterval(() => {
      this.setState({
        currentTime: new Date(), // Cập nhật thời gian
        highlightTitle: !this.state.highlightTitle
      });
    }, 1000);
  }

  calculateTeamHourlySoldData = (hourlySoldData) => {
    if (hourlySoldData.length == 0)
      return [];

    var teamNames = Object.keys(this.shopTeams);

    var teamHourlySoldData = {};

    teamNames.map(teamName => {
      var shopNames = this.shopTeams[teamName].split(",");

      // hourly
      var teamShopSoldData = shopNames.map(shopName => this.getHourlySoldData(shopName));

      var firstShopSoldData = teamShopSoldData[0];

      teamHourlySoldData[teamName] = firstShopSoldData.map((soldData, index) => {
        var timestamp = soldData.timestamp;
        var total_sold = 0;
        var delta_sold = 0;

        teamShopSoldData.map(shopSoldData => {
          var soldDataX = shopSoldData[index];

          if (!soldDataX && index > 0)
            soldDataX = shopSoldData[index - 1];

          total_sold += soldDataX.total_sold;
          delta_sold += soldDataX.delta_sold;
        });

        return {
          shop_names: teamName,
          team: teamName,
          type: 'HOURLY',
          timestamp,
          total_sold,
          delta_sold
        }
      });
    });

    console.log("AAA calculateTeamHourlySoldData", teamHourlySoldData);

    return teamHourlySoldData;
  }

  calculateTeamDailySoldData = (dailySoldData) => {
    if (dailySoldData.length == 0)
      return [];

    var teamNames = Object.keys(this.shopTeams);

    var teamDailySoldData = {};

    teamNames.map(teamName => {
      var shopNames = this.shopTeams[teamName].split(",");

      // hourly
      var teamShopSoldData = shopNames.map(shopName => this.getDailySoldData(shopName));

      var firstShopSoldData = teamShopSoldData[0];

      teamDailySoldData[teamName] = firstShopSoldData.map((soldData, index) => {
        var timestamp = soldData.timestamp;
        var total_sold = 0;
        var delta_sold = 0;

        teamShopSoldData.map(shopSoldData => {
          var soldDataX = shopSoldData[index];
          total_sold += soldDataX.total_sold;
          delta_sold += soldDataX.delta_sold;
        });

        return {
          shop_names: teamName,
          team: teamName,
          type: 'HOURLY',
          timestamp,
          total_sold,
          delta_sold
        }
      });
    });

    console.log("AAA calculateTeamDailySoldData", teamDailySoldData);

    return teamDailySoldData;
  }

  componentWillReceiveProps(props) {
    var { currentShopSoldData, hourlySoldData, dailySoldData } = props;

    var teamHourlySoldData = this.calculateTeamHourlySoldData(hourlySoldData);
    var teamDailySoldData = this.calculateTeamDailySoldData(dailySoldData);

    this.setState({ hourlySoldData, dailySoldData, teamHourlySoldData, teamDailySoldData });

    // console.log("AAAA hourlySoldData", hourlySoldData);

    if (JSON.stringify(currentShopSoldData) != JSON.stringify(this.props.currentShopSoldData.length)) {
      // console.log("XXXX currentShopSoldData", currentShopSoldData);

      var currentListingSoldData = {};

      currentShopSoldData.map(soldData => {
        var listingIds = soldData.sold_listing_ids ? soldData.sold_listing_ids.split(",") : [];
        var imgCoverUrls = soldData.img_cover_urls ? soldData.img_cover_urls.split(",") : [];
        var titles = soldData.titles ? soldData.titles.split(",") : [];

        console.log("XXXX", listingIds, imgCoverUrls, titles)

        listingIds.map((listingId, index) => {
          if (!currentListingSoldData[listingId])
            currentListingSoldData[listingId] = {
              listing_id: listingId,
              sold_count: 0,
              sold_timestamps: []
            };

          currentListingSoldData[listingId].sold_count++;
          currentListingSoldData[listingId].sold_timestamps.push(soldData.timestamp);
          currentListingSoldData[listingId].img_cover_url = imgCoverUrls[index];
          currentListingSoldData[listingId].title = titles[index];
        })
      });

      this.currentListingSoldData = currentListingSoldData;

      console.log("XXXX currentListingSoldData", currentListingSoldData);

      // var listingIds = Object.keys(currentListingSoldData);
      // this.props.getListingByIds(listingIds);
      // // });
    }

    // if (props.listingByIds.length > 0 && this.currentListingSoldData) {
    //   var listingByIds = props.listingByIds.filter(listing => listing.shop_name == this.state.currentShopName);

    //   // var { currentListingSoldData } = this.state;
    //   var currentListingSoldData = this.currentListingSoldData;

    //   var currentListingSoldArray = listingByIds.map(listing => {
    //     return { ...listing, ...currentListingSoldData[listing.listing_id] }
    //   });

    //   currentListingSoldArray = currentListingSoldArray.sort((a, b) => b.soldCount - a.soldCount);

    //   console.log("CCCC", currentListingSoldArray);

    //   this.setState({
    //     currentListingSoldArray
    //   })
    // }
  }

  shopTotalSoldData = {}
  currentListingSoldData = {}
  firstRun = true

  updateShopTotalSold = (shopName, totalSold) => {
    // var { shopTotalSoldData } = this.state;

    this.shopTotalSoldData[shopName] = totalSold;

    // this.setState({ shopTotalSoldData });
  }

  handleShowShopListingSold = (shopName) => {
    console.log("XXX", shopName);

    this.setState({
      currentShopName: shopName,
      showModalListingSold: true
    });

    // get sold data
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    const timeMin = today.getTime() / 1000;

    today.setHours(23, 59, 59, 0);
    const timeMax = today.getTime() / 1000;

    this.props.getShopSold({
      shopName,
      timeMin, timeMax
    })
  }

  handleCloseModalListingSold = (e) => {
    this.setState({
      showModalListingSold: false,
      // currentShopSoldData: [],
      // listingByIds: [],
      // currentListingSoldData: {},
      // currentListingSoldArray: []
    });

    this.currentListingSoldData = {}

    console.log("XXXX handleCloseModalListingSold", this.currentListingSoldData);
  }

  handleSelectViewMode = (e) => {
    var teamDailySoldData = this.calculateTeamDailySoldData(this.state.dailySoldData);

    console.log("XXX calculateTeamDailySoldData", teamDailySoldData);

    this.setState({ selectedViewMode: e.target.value, teamDailySoldData });
  }

  renderChartViewModeOption = (resinTotalSold) => {
    var { selectedViewMode } = this.state;

    return (
      
      <div className="d-flex flex-column flex-md-row">
        <Form.Check
          type="radio"
          label="Black Friday"
          value={CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY}
          name="group1"
          onChange={this.handleSelectViewMode}
          checked={selectedViewMode === CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY}
          className="me-4"
        />

        <Form.Check
          type="radio"
          label="Hôm nay"
          value={CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY}
          name="group1"
          onChange={this.handleSelectViewMode}
          checked={selectedViewMode === CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY}
          className="me-4"
        />

        {/* <Form.Check
          type="radio"
          label="Hôm qua"
          value={CONFIGS.VIEW_MODE_SHOP_SOLD.YESTERDAY}
          name="group1"
          onChange={this.handleSelectViewMode}
          checked={selectedViewMode === CONFIGS.VIEW_MODE_SHOP_SOLD.YESTERDAY}
          className="me-4"
        /> */}

        <Form.Check
          type="radio"
          label="7 ngày"
          value={CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS}
          name="group1"
          onChange={this.handleSelectViewMode}
          checked={selectedViewMode === CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS}
          className="me-4"
        />

        <Form.Check
          type="radio"
          label="14 ngày"
          value={CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS}
          name="group1"
          onChange={this.handleSelectViewMode}
          checked={selectedViewMode === CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS}
          className="me-4"
        />

        <Form.Check
          type="radio"
          label="30 ngày"
          value={CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS}
          name="group1"
          onChange={this.handleSelectViewMode}
          checked={selectedViewMode === CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS}
          className="me-4"
        />
      </div>
    )
  }

  renderShopListingSoldModal = () => {
    var shopSold = this.shopTotalSoldData[this.state.currentShopName];
    var currentListingSoldArray = Object.keys(this.currentListingSoldData).map(listingId => this.currentListingSoldData[listingId]);
    currentListingSoldArray = currentListingSoldArray.sort((a, b) => Math.min(...b.sold_timestamps) - Math.min(...a.sold_timestamps));

    console.log("XXXX currentListingSoldArray", currentListingSoldArray);

    return (
      <Modal size="xl" className="modal-shop-listing-sold"
        show={this.state.showModalListingSold}
        onHide={this.handleCloseModalListingSold}
      >
        <Modal.Header closeButton>
          <span className="text-modal-title text-center">{this.state.currentShopName}</span>
        </Modal.Header>
        <Modal.Body>

          {currentListingSoldArray.length == 0 ?
            <>
              <span className="text-center"><h4>Đang lấy dữ liệu ... </h4></span>

              <FerrisWheelSpinner loading={true} color="#17a2b8" />
            </> :
            <>
              <Row className="text-header">
                <Col md="2">Listing ID</Col>
                <Col md="2">Ảnh</Col>
                <Col md="3">Tên sản phẩm</Col>

                <Col md="2"><span className="text-red text-bold text-center">{shopSold} sold</span></Col>
                <Col md="3">Thời gian</Col>
              </Row>
              {currentListingSoldArray.map((listing, index) => (
                <Row className="listing-item">
                  <Col md="2">
                    <span className="text-bold">{listing.listing_id}</span>
                  </Col>
                  <Col md="2">
                    <a href={`https://etsy.com/listing/${listing.listing_id}`} target="_blank" title='View this listing on Etsy' className="truncated-text">
                      <Card.Img variant="top" src={listing.img_cover_url} />
                    </a>
                  </Col>
                  <Col md="3" className="truncated-text">
                    {listing.title}
                  </Col>

                  <Col md="2">
                    <span className="text-red">{listing.sold_count} sold</span>
                  </Col>
                  <Col md="3">
                    {listing.sold_timestamps &&
                      listing.sold_timestamps.sort((a, b) => b - a).map(timestamp => {
                        console.log("TTT", timestamp);
                        const formattedTime = moment.unix(timestamp).format("HH:mm");

                        return <Badge bg="success" className="mr-3">{formattedTime}</Badge>;
                      })}
                  </Col>
                </Row>
              ))}
            </>
          }

        </Modal.Body>
      </Modal>
    )
  }

  getHourlySoldData = (shopName) => {
    var shopHourlySoldData = this.state.hourlySoldData.filter(x => x._id == shopName);

    if (shopHourlySoldData.length > 0)
      shopHourlySoldData = shopHourlySoldData[0].sold_data;

    return shopHourlySoldData;
  }

  getDailySoldData = (shopName) => {
    var shopDailySoldData = this.state.dailySoldData.filter(x => x._id == shopName);

    if (shopDailySoldData.length > 0)
      shopDailySoldData = shopDailySoldData[0].sold_data;

    console.log("VVV getDailySoldData ", shopName, shopDailySoldData);

    return shopDailySoldData;
  }

  getTeamHourlySoldData = (teamName) => {
    var teamHourlySoldData = [];

    if (this.state.teamHourlySoldData[teamName])
      teamHourlySoldData = this.state.teamHourlySoldData[teamName];

    console.log("teamHourlySoldData", this.state.teamHourlySoldData, teamName, teamHourlySoldData);

    return teamHourlySoldData;
  }

  getTeamDailySoldData = (teamName) => {
    console.log("KKK teamDailySoldData", this.state.teamDailySoldData);

    var teamDailySoldData = this.state.teamDailySoldData.filter(x => x.team == teamName);

    if (teamDailySoldData.length > 0)
      teamDailySoldData = teamDailySoldData[0].sold_data;

    return teamDailySoldData;
  }

  updateTOP2TeamChartData = (team, data) => {
    return false;
    this.TOP2TeamChartData[team] = data;

    // this.setState({ TOP2TeamChartData });

    console.log("XXX updateTOP2TeamChartData", this.TOP2TeamChartData);
  }

  renderTOP2TeamChart = () => {
    var { selectedViewMode } = this.state;
    var teamANT = this.TOP2TeamChartData["ANT"];
    var teamVAN = this.TOP2TeamChartData["VĂN"];

    if (teamANT.length == 0 || teamVAN.length == 0)
      return "";

    const options = {
      title: {
        text: "SO SÁNH 2 TEAM",
      },
      xAxis: {
        // title: { text: "Giờ" },
        type: 'datetime',
        categories: teamANT.map(x => x[0]),
        labels: {
          formatter: function() {
              // Lấy giờ từ timestamp
              let date = new Date(this.value);
              if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY)
                return date.getHours() + "h";  // Chỉ lấy giờ và thêm "h"
              else if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS
                || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS
                || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS
              ) {
                return moment(date).format("DD/MM");
              }
          }
        }
      },
      yAxis: {
        title: { text: "Sold" },
        min: 0,
      },
      tooltip: {
        shared: true, // Bật chế độ share thông tin
        formatter: function () {
          const time = moment(this.x).format('HH:mm');
          let tooltipHtml = ``; // Hiển thị giờ

          if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY) {
            const time = moment(this.x).format('HH:mm');
            tooltipHtml = `<b>${time}</b><br/>`; // Hiển thị giờ
          } else if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS
            || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS
            || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS
          ) {
            tooltipHtml = `<b>${moment(this.x).format("DD/MM")}</b><br/>`;
          }
    
          this.points.forEach((point) => {
            tooltipHtml += `${point.series.name}: <b>${point.y} sold</b><br/>`; // Thông tin từng shop
          });
    
          return tooltipHtml;
        },
      },
      series: [
        {
          name: "Team ANT",
          data: teamANT.map(x => x[1]),
          color: '#2f7ed8',
        },
        {
          name: "Team VĂN",
          data: teamVAN.map(x => x[1]),
          color: '#f28f43',
        },
      ],
      chart: {
        type: "line",
        events: {
          render: function () {
            const series = this.series;
            let totalSoldShopA = 0;
            let totalSoldShopB = 0;
  
            series.forEach((serie) => {
              if (serie.name === 'Team ANT') {
                totalSoldShopA = serie.data.reduce((sum, point) => sum + point.y, 0);
              } else if (serie.name === 'Team VĂN') {
                totalSoldShopB = serie.data.reduce((sum, point) => sum + point.y, 0);
              }
            });
  
            // Kiểm tra và cập nhật văn bản nếu đã tồn tại, thay vì tạo mới
            if (this.annotation) {
              this.annotation.attr({
                text: `<span style="color:${series[0].color};">Team ANT: ${totalSoldShopA} sold</span><br /><span style="color:${series[1].color};">Team VĂN: ${totalSoldShopB} sold</span>`
              });
            } else {
              // Nếu chưa có annotation, tạo mới
              this.annotation = this.renderer
                .text(
                  `<span style="color:${series[0].color};">Team ANT: ${totalSoldShopA} sold</span><br /><span style="color:${series[1].color};">Team VĂN: ${totalSoldShopB} sold</span>`,
                  this.plotWidth - 80,
                  20
                )
                .css({
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#333',
                })
                .add();
            }
          },
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true, // Bật hiển thị dữ liệu trên đồ thị
          },
          enableMouseTracking: true, // Duy trì hover để xem tooltip
        },
      },
    };
  
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }

  renderTOP2TeamChartColumn = () => {
    var { selectedViewMode } = this.state;
    var teamANT = this.TOP2TeamChartData["ANT"];
    var teamVAN = this.TOP2TeamChartData["VĂN"];

    if (teamANT.length == 0 || teamVAN.length == 0)
      return "";

    const options = {
      chart: {
        type: 'column',
        events: {
          render: function () {
            const series = this.series;
            let totalSoldShopA = 0;
            let totalSoldShopB = 0;
  
            series.forEach((serie) => {
              if (serie.name === 'Team ANT') {
                totalSoldShopA = serie.data.reduce((sum, point) => sum + point.y, 0);
              } else if (serie.name === 'Team VĂN') {
                totalSoldShopB = serie.data.reduce((sum, point) => sum + point.y, 0);
              }
            });
  
            // Kiểm tra và cập nhật văn bản nếu đã tồn tại, thay vì tạo mới
            if (this.annotation) {
              this.annotation.attr({
                text: `<span style="color:${series[0].color};">Team ANT: ${totalSoldShopA} sold</span><br /><span style="color:${series[1].color};">Team VĂN: ${totalSoldShopB} sold</span>`
              });
            } else {
              // Nếu chưa có annotation, tạo mới
              this.annotation = this.renderer
                .text(
                  `<span style="color:${series[0].color};">Team ANT: ${totalSoldShopA} sold</span><br /><span style="color:${series[1].color};">Team VĂN: ${totalSoldShopB} sold</span>`,
                  this.plotWidth - 80,
                  20
                )
                .css({
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#333',
                })
                .add();
            }
          },
        },
      },    
      title: {
        text: "SO SÁNH 2 TEAM",
      },
      xAxis: {
        categories: teamANT.map(x => x[0]),
        crosshair: true,
        title: {
          text: 'datetime',
        },
        labels: {
          formatter: function() {
              // Lấy giờ từ timestamp
              let date = new Date(this.value);
              if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY)
                return date.getHours() + "h";  // Chỉ lấy giờ và thêm "h"
              else if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS
                || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS
                || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS
              ) {
                return moment(date).format("DD/MM");
              }
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Sold',
        },
      },
      tooltip: {
        shared: true, // Bật chế độ share thông tin
        formatter: function () {
          const time = moment(this.x).format('HH:mm');
          let tooltipHtml = ``; // Hiển thị giờ

          if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.TODAY) {
            const time = moment(this.x).format('HH:mm');
            tooltipHtml = `<b>${time}</b><br/>`; // Hiển thị giờ
          } else if (selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_7_DAYS
            || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_14_DAYS
            || selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.LAST_30_DAYS
          ) {
            tooltipHtml = `<b>${moment(this.x).format("DD/MM")}</b><br/>`;
          }
    
          this.points.forEach((point) => {
            tooltipHtml += `${point.series.name}: <b>${point.y} sold</b><br/>`; // Thông tin từng shop
          });
    
          return tooltipHtml;
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true, // Bật hiển thị giá trị
            format: '{y}', // Hiển thị giá trị của trục Y
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#000',
            },
          },
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Team ANT",
          data: teamANT.map(x => x[1]),
          color: '#2f7ed8', // Màu cột của Shop A
        },
        {
          name: "Team VĂN",
          data: teamVAN.map(x => x[1]),
          color: '#f28f43', // Màu cột của Shop B
        },
      ],
    };
    
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }

  render() {
    var { hourlySoldData, dailySoldData } = this.props;

    if (this.firstRun && hourlySoldData.length > 0 && dailySoldData.length > 0) {
      this.firstRun = false;

      var teamHourlySoldData = this.calculateTeamHourlySoldData(hourlySoldData);
      var teamDailySoldData = this.calculateTeamDailySoldData(dailySoldData);

      this.setState({ teamHourlySoldData, teamDailySoldData });
    }

    console.log("KKK teamDailySoldData", this.state.teamDailySoldData);
    const { currentTime, selectedViewMode } = this.state;
    const timeString = currentTime.toLocaleTimeString();

    const currentDate = moment().format('DD/MM/YYYY');

    var shopNames = this.shopNames.sort((a, b) => this.shopTotalSoldData[b] - this.shopTotalSoldData[a]);
    // var shopGroups = Array.from({ length: 3 }, (_, i) => shopNames.slice(i * 3, i * 3 + 3));

    var teamNames = Object.keys(Object.fromEntries(Object.entries(this.shopTeams).slice(1))).sort((a, b) => this.shopTotalSoldData[b] - this.shopTotalSoldData[a]);

    var resinTotalSold = 0;

    shopNames.map(shopName => {
      resinTotalSold += this.shopTotalSoldData[shopName]
    });

    resinTotalSold = resinTotalSold / this.state.totalTopShopSoldPercentage;

    var teamSoldPercentages = teamNames.map(teamName => {
      var teamShopNames = this.shopTeams[teamName].split(",");

      var teamSoldCount = 0;

      teamShopNames.map(shopName => {
        teamSoldCount += this.shopTotalSoldData[shopName]
      });

      return _roundNumber(teamSoldCount * 100 / resinTotalSold, 2);
    })

    var nicheName = Object.keys(this.shopTeams)[0];

    return (
      <>
        <Container fluid id="shop-sold">
          <Row>
            {this.state.selectedViewMode == CONFIGS.VIEW_MODE_SHOP_SOLD.BLACK_FRIDAY?
              <h2 className={`text-center ${this.state.highlightTitle? 'text-red':''}`}>BLACK FRIDAY 2024</h2>
              :<h2 className='text-center'>THEO DÕI SOLD NGÁCH RESIN</h2>
            }
            
            <div className="text-center mb-2 font-18 cursor-pointer">
            {/* <span className={this.state.driver1Status? 'text-blue-chart mr-2': 'text-red mr-2'} title={this.state.driver1Status? 'BOT 1: ACTIVE': 'BOT 1: INACTIVE'}>⬤</span>
            <span className={this.state.driver2Status? 'text-blue-chart': 'text-red'}  title={this.state.driver2Status? 'BOT 2: ACTIVE': 'BOT 2: INACTIVE'}>⬤</span> */}
            </div>
            
          </Row>

          {/* <Row>
            {shopGroups.map((group, index) => (
              <div key={index} className="col-md-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Shop Name</th>
                      <th>Sold</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.map((shopName, idx) => (
                      <tr key={idx}>
                        <td>{index*3 + idx + 1}</td>
                        <td>{shopName}</td>
                        <td>{shopTotalSoldData[shopName]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}

          </Row> */}

          <Row>
            <Col md="6" className="d-flex d-md-block justify-content-center align-items-center view-mode-option">
              {this.renderChartViewModeOption(resinTotalSold)}
            </Col>
            <Col md="6">
              <div className="date-time">
                <span className="text-blue mr-4">{currentDate}</span>
                <span className="text-red">{timeString}</span>
              </div>
            </Col>
          </Row>

          <Row className="team-title text-center">
            <h3 className="mt-2">NGÁCH RESIN</h3>
          </Row>

          <Row className="mt-4">
            {/* <Col md="3" className="mb-4"></Col> */}
            <Col md="12" className="mb-4">
              {/* <h4 className="text-center text-bold">#{index+1}</h4> */}
              {/* <div className="text-center mb-2">
                  <Badge bg="success" className="font-16">
                    {resinTotalSold > 0 ?
                      `#${index + 1} - ${teamSoldPercentages[index]}%` : "Calculating ..."}
                  </Badge>
                </div> */}

              <ShopSoldChart shopName={nicheName}
                viewMode={selectedViewMode}
                niche={true}
                updateShopTotalSold={this.updateShopTotalSold}
                handleShowShopListingSold={this.handleShowShopListingSold}
                hourlySoldData={this.state.teamHourlySoldData[nicheName]}
                dailySoldData={this.state.teamDailySoldData[nicheName]}
                shopTeam={this.shopTeams[nicheName]}
              />
            </Col>
          </Row>

          <Row className="team-title text-center">
            <h3 className="mt-2">TEAM</h3>
          </Row>

          <Row className="mt-4">
            {teamNames.map((teamName, index) =>
              <Col md="6" className="mb-4">
                {/* <h4 className="text-center text-bold">#{index+1}</h4> */}
                <div className="text-center mb-2">
                  <Badge bg="success" className="font-16">
                    {resinTotalSold > 0 ?
                      `#${index + 1} - ${teamSoldPercentages[index]}%` : "Calculating ..."}
                  </Badge>
                </div>

                <ShopSoldChart shopName={teamName}
                  viewMode={selectedViewMode}
                  updateShopTotalSold={this.updateShopTotalSold}
                  handleShowShopListingSold={this.handleShowShopListingSold}
                  hourlySoldData={this.state.teamHourlySoldData[teamName]}
                  dailySoldData={this.state.teamDailySoldData[teamName]}
                  shopTeam={this.shopTeams[teamName]}
                  // updateTOP2TeamChartData={(data) => this.updateTOP2TeamChartData(teamName, data)}
                />
              </Col>
            )}
          </Row>

          {/* <Row>
            <Col md="6">{this.renderTOP2TeamChart()}</Col>
            <Col md="6">{this.renderTOP2TeamChartColumn()}</Col>
          </Row> */}

          <Row className="mt-3 text-center shop-title">
            <h3 className="mt-2">SHOP</h3>
          </Row>

          <Row className="mt-4">
            {shopNames.map((shopName, index) =>
              <Col md="3" className="mb-4">
                <div className="text-center mb-2">
                  <Badge bg="primary" className="font-16 mr-2">
                    {resinTotalSold ?
                      `#${index + 1} - ${_roundNumber(this.shopTotalSoldData[shopName] * 100 / resinTotalSold, 2)}%`
                      : 'Calculating ...'}
                  </Badge>
                  {/* <Badge pill bg="danger" className="font-16"> </Badge> */}
                </div>

                <ShopSoldChart shopName={shopName}
                  viewMode={selectedViewMode}
                  updateShopTotalSold={this.updateShopTotalSold}
                  handleShowShopListingSold={this.handleShowShopListingSold}
                  hourlySoldData={this.getHourlySoldData(shopName)}
                  dailySoldData={this.getDailySoldData(shopName)}
                />
              </Col>
            )}
          </Row>
        </Container>

        {this.renderShopListingSoldModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentShopSoldData: state.shopSoldReducer.currentShopSoldData,
    listingByIds: state.listingReducer.listingByIds,

    hourlySoldData: state.shopSoldReducer.hourlySoldData,
    dailySoldData: state.shopSoldReducer.dailySoldData
  }
}

export default connect(mapStateToProps, {
  getShopSold, getShopSoldStatGroup, getListingByIds
})(ShopSold);