import { combineReducers } from "redux";
import { authenReducer } from "./authenReducer";
import { myRecordReducer } from "./myRecordReducer";
import { listingReducer } from "./listingReducer";
import { shopReducer } from "./shopReducer";
import { shopSoldReducer } from "./shopSoldReducer";
import { taskReducer } from "./taskReducer";
import { collectionReducer } from "./collectionReducer";
import { botReducer } from "./botReducer";
import { socketReducer } from "./socketReducer";
import { miscReducer } from "./miscReducer";
import { localDataReducer } from "./localDataReducer";
import { autoP2PReducer } from "./autoP2PReducer";

const reducers = combineReducers({
  authenReducer,
  myRecordReducer,
  listingReducer,
  shopReducer,
  shopSoldReducer,
  taskReducer,
  collectionReducer,
  botReducer,
  socketReducer,
  miscReducer,
  localDataReducer,
  autoP2PReducer
});

export default reducers;
