import { TYPES } from "./types";
import { autoP2PService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getAutoP2PPayments = (query) => async (dispatch) => {
  try {
    console.log("@@@ getAutoP2PPayments", query);
    const result = await autoP2PService.getAutoP2PPayments(query);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENTS_SUCCESS,
        payload: { data }
      });

      // dispatch(setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, true))
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_AUTOP2P_PAYMENTS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const updateAutoP2PPayment = (payment, getLatestData) => async (dispatch) => {

  try {
    const result = await autoP2PService.updateAutoP2PPayments([payment]);
    const data = result.data.message;

    console.log("@@@ updateAutoP2PPayment", data);
    
    if (data) {
      if (getLatestData) {
        await dispatch(getAutoP2PPayments({ paymentType: payment.type }));
      } else {
        dispatch({
          type: TYPES.ACTION_UPDATE_AUTOP2P_PAYMENT_SUCCESS,
          payload: { payment: data.result[0] }
        });
      }
    } else {
      dispatch({
        type: TYPES.ACTION_UPDATE_AUTOP2P_PAYMENT_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const autoP2PAction = {
  getAutoP2PPayments,
  updateAutoP2PPayment
};
