import { TYPES } from "./types";
import { listingService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getListings = (query, exportGGsheet) => async (dispatch) => {
  try {
    const result = await listingService.getListings(query);

    if (exportGGsheet)
      dispatch({});

    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LISTINGS_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LISTINGS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const addListingsToCollection = (_query, collectionName) => async (dispatch) => {
  // try {
    let query = {..._query, 
      "project": "listing_id",
      "collection_name": collectionName
    };

    const result = await listingService.getListings(query);

    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_ADD_ALL_LISTING_TO_NEW_COLLECTION_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_ADD_ALL_LISTING_TO_NEW_COLLECTION_FAIL,
        payload: {},
      });
    }
  // } catch (error) {
  //   dispatch({
  //     type: TYPES.ACTION_CALL_API_FAIL,
  //     payload: {
  //       message: error,
  //     },
  //   });
  // }
};

export const getListingByIds = (listingIds) => async (dispatch) => {
  try {
    const result = await listingService.getListingByIds(listingIds);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LISTING_BY_IDS_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LISTING_BY_IDS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const updateListingsHeyData = (listingIds) => async (dispatch) => {
  try {
    const result = await listingService.updateListingsHeyData(listingIds);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_UPDATE_LISTINGS_HEYDATA_SUCCESS,
        payload: { data }
      });
    } else {
      dispatch({
        type: TYPES.ACTION_UPDATE_LISTINGS_HEYDATA_FAIL,
        payload: {}
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const postListings = (listings) => async (dispatch) => {
  console.log('Post listings', listings);
  
  try {
    const result = await listingService.postListings(listings);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_POST_LISTINGS_SUCCESS,
        payload: { }
      });
    } else {
      dispatch({
        type: TYPES.ACTION_POST_LISTINGS_FAIL,
        payload: { }
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getListing30DaysSold = (listing_id) => async (dispatch) => {  
  console.log('getListing30DaysSold', listing_id);

  try {
    const result = await listingService.getListing30DaysSold(listing_id);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LISTINGS_30_DAYS_SOLD_SUCCESS,
        payload: { data }
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LISTINGS_30_DAYS_SOLD_FAIL,
        payload: { }
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const listingAction = {
  getListings,
  addListingsToCollection,
  getListingByIds,
  postListings,
  updateListingsHeyData,
  getListing30DaysSold
};
