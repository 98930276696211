/* eslint-disable func-names */
import React, { Component } from "react";
import Select from 'react-select';
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as moment from 'moment'
import { QRCodeCanvas } from "qrcode.react"
import { updateAutoP2PPayment } from "../../actions"
import { CONFIGS } from '../../configs'
import { BANKS_LIST, formatTime, _formatCurrency, getDiffSeconds } from '../../utils'
import { QRPay, BanksObject } from 'vietnam-qr-pay';

import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'

import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    InputGroup,
    Collapse,
    Modal,
    Table
} from "react-bootstrap";
import { basename } from "path";

class AutoP2PPayment extends Component {
    constructor() {
        super();

        this.state = {
            payment: "",
            showQR: false,
            paymentOption: "",
            selectedBank: {
                value: ""
            },
            size: 256
        }

        this.qrContainerRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            payment: this.props.payment,
            showQR: !this.props.hideQR,
            paymentOption: this.props.paymentOption,
            selectedBank: {
                label: this.props.payment.bank_name,
                value: this.props.payment.bank_name
            }
        })

        this.updateSize();
        window.addEventListener('resize', this.updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize);
    }

    updateSize = () => {
        const containerWidth = this.qrContainerRef.current
          ? this.qrContainerRef.current.offsetWidth
          : 256;

        this.setState({ size: containerWidth });
    };

    componentWillReceiveProps(props) {
        // if (props.payment)
            this.setState({
                payment: props.payment,
                paymentOption: props.paymentOption,
                selectedBank: {
                    label: props.payment.bank_name,
                    value: props.payment.bank_name
                }
            })
    }

    handleChangeFullName = (e) => {
        this.setState({
            payment: { ...this.state.payment, full_name: e.target.value }
        })
    }

    handleChangeAccountNumber = (e) => {
        this.setState({
            payment: { ...this.state.payment, account_number: e.target.value }
        })
    }

    handleChangeMessage = (e) => {
        this.setState({
            payment: { ...this.state.payment, message: e.target.value }
        })
    }

    handleSelectBank = (value) => {
        this.setState({ selectedBank: value })
    }

    renderQR = (payment, message) => {
        console.log("XXX renderQR", message);
        
        try {
            var bankBin = BANKS_LIST.filter(bank => bank.shortName == this.state.selectedBank.value)[0].atmBin;

            const qrPay = QRPay.initVietQR({
                bankBin,
                bankNumber: payment.account_number.trim(), // Số tài khoản
                amount: `${payment.amount}`, // Số tiền
                purpose: message.trim(), // Nội dung chuyển tiền
            })
            const content = qrPay.build()

            return content;
        } catch {
            return "";
        }
    }

    handleSubmitError = () => {
        this.props.updateAutoP2PPayment({
            ...this.state.payment,
            status: CONFIGS.AUTOP2P_PAYMENT_STATUS.ERROR_INPUT
        }, true);
    }

    handleSubmitDelete = () => {
        this.props.updateAutoP2PPayment({
            ...this.state.payment,
            status: CONFIGS.AUTOP2P_PAYMENT_STATUS.ERROR_DELETE
        }, true); 
    }

    handleSubmit = () => {
        // var status = this.state.payment.status;

        // if (status == CONFIGS.AUTOP2P_PAYMENT_STATUS.ERROR_INPUT)
        var status = CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_2;

        this.props.updateAutoP2PPayment({
            ...this.state.payment,
            bank_name: this.state.selectedBank.value,
            status,
            manual_save: true
        }, true);
    }

    handleConfirmPayment = () => {
        // if (this.state.payment.error && this.state.payment.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_EXCHANGE)
        //     this.props.updateAutoP2PPayment({
        //         ...this.state.payment,
        //         timestamp_step3: Date.now(),
        //         error: ""
        //     }, true);

        this.props.updateAutoP2PPayment({
            ...this.state.payment,
            bank_name: this.state.selectedBank.value,
            status: CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_TOOL,
            timestamp_step3: Date.now()
        }, true);
    }

    renderStatus = (payment) => {
        var statusBadge = "";

        switch (payment.status) {
            case CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_1:
                statusBadge = <Badge pill bg="info">MỚI</Badge>;
                break;
            case CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_2:
                statusBadge = <Badge pill bg="warning">CHƯA THANH TOÁN</Badge>;
                break;
            case CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_TOOL:
                statusBadge = <Badge pill bg="primary">ĐÃ THANH TOÁN</Badge>;
                break;
            case CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_EXCHANGE:
                statusBadge = <>
                    <Badge pill bg="success" className="mr-2">ĐÃ XÁC NHẬN TRÊN SÀN</Badge>
                    {payment.timestamp_step4 ?
                        <Badge pill bg="danger">
                            {getDiffSeconds(payment.timestamp_step1, payment.timestamp_step4)}
                        </Badge>:""
                    }
                </>;
                break;
        }

        console.log("XXX payment.status", payment.status);

        return <>
        {payment.type == CONFIGS.AUTOP2P_PAYMENT_TYPES.BUY?
            <Badge pill className="mr-2" bg="success">MUA</Badge>
            :<Badge pill className="mr-2" bg="danger">BÁN</Badge>
            }
            {statusBadge}
        </>
    }

    toggleShowQR = () => {
        var showQR = !this.state.showQR;

        this.setState({ showQR });
        this.updateSize();
    }

    render() {
        console.log("@@@ AutoP2PPayment state", this.state);
        var { payment, paymentOption, size } = this.state;

        if (!payment || !paymentOption)
            return "";

        var message = payment.message;

        if (paymentOption == CONFIGS.AUTOP2P_PAYMENT_OPTIONS.ORDER_ID)
            message = payment.order_id;
        else if (paymentOption == CONFIGS.AUTOP2P_PAYMENT_OPTIONS.TEMPLATE_1)
            message = `${CONFIGS.AUTOP2P_PAYMENT_MESSAGES[0]}`;
        else if (paymentOption == CONFIGS.AUTOP2P_PAYMENT_OPTIONS.TEMPLATE_2)
            message = `${CONFIGS.AUTOP2P_PAYMENT_MESSAGES[1]} ${payment.order_id}`;

        if (payment.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_1)
            return <Row className="payment-item">
                <Col md="5" style={{height: "200px"}}>
                    <Row>
                        <Col sm="6"><b>Order ID</b></Col>
                        <Col sm="6">{payment.order_id}</Col>
                    </Row>
                    <Row>
                        <Col sm="6"><b>Thời gian lấy Order ID</b></Col>
                        <Col sm="6">{formatTime(payment.timestamp_step1)}</Col>
                    </Row>
                    <Row>
                        <Col md="6">{this.renderStatus(payment)}</Col>
                    </Row>
                    <Row>
                        <p className="order-link">
                            <a href={payment.link} style={{color: "blue"}}>{payment.link}</a>
                        </p>
                    </Row>
                </Col>
                <Col md="7"></Col>
            </Row>

        console.log("XXX PAYMENT", payment);

        try {
            return (
                <Row className="payment-item">
                    <Col md="5" className="extra-infos pt-1">
                        <Row className="raw-info">
                            <Col sm="6" className="mt-0"><b>Order ID</b></Col>
                            <Col md="6" className="mt-0">
                                <a href={payment.link} style={{color: "blue"}}>{payment.order_id}</a>
                            </Col>
                            {payment.extra_infos.slice(1).map((info, index) => {
                                if (info.includes("Search bank"))
                                    return "";
    
                                var element = (index % 2 == 0) ?
                                    <b>{info}</b> : info;
    
                                // if (index > 0)
                                return <Col sm="6" className="word-break mt-0">{element}</Col>
                            })}
                        </Row>
                        <Row className="report-timestamp">
                            <Col md="12" className="mb-1">{this.renderStatus(payment)}</Col>
                            {/* <Col sm="2" className="mb-0">{formatTime(payment.timestamp_step1, "DD/MM/YYYY")}</Col> */}

                            <Col sm="3" className="mb-0"><b>Quét Order ID</b></Col>
                            <Col sm="3" className="mb-0">{formatTime(payment.timestamp_step1)}</Col>
                            <Col sm="3" className="mb-0"><b>Chuyển tiền</b></Col>
                            <Col sm="3" className="mb-0">{formatTime(payment.timestamp_step3)}</Col>
                            
                            {/* <Col sm="2"></Col> */}
                            <Col sm="3" className="mb-0"><b>Quét Thông tin</b></Col>
                            <Col sm="3" className="mb-0">{formatTime(payment.timestamp_step2)}</Col>
                            
                            <Col sm="3" className="mb-0"><b>Xác nhận</b></Col>
                            <Col sm="3" className="mb-0">{formatTime(payment.timestamp_step4)}</Col>
                        </Row>
                    </Col>
                    <Col md="7">
                        <Row style={{ height: "100%" }}>
                            <Col md="9" className="pl-4">
                                {/* <Row className="mt-3">
                    <h4>THÔNG TIN CHUYỂN KHOẢN</h4>
                    </Row> */}
                                <Row className="mt-3">
                                    <InputGroup className="mb-1">
                                        <InputGroup.Text>ORDER ID</InputGroup.Text>
                                        <Form.Control
                                            value={payment.order_id}
                                            disabled={true}
                                        />
                                    </InputGroup>
                                </Row>
                                <Row>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text>HỌ TÊN</InputGroup.Text>
                                        <Form.Control
                                            value={payment.full_name}
                                            onChange={this.handleChangeFullName}
                                        />
                                    </InputGroup>
                                </Row>
                                {/* <Row>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text>NGÂN HÀNG</InputGroup.Text>
                                        <Form.Select
                                            value={payment.bank_name}
                                            onChange={this.handleSelectBank}
                                        >
                                            {BANKS_LIST.map(bank => <option value={bank.shortName}>{bank.shortName}</option>)}
                                        </Form.Select>
                                    </InputGroup>
                                </Row> */}
                                <Row>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text>NGÂN HÀNG</InputGroup.Text>
                                        <Select
                                            options={BANKS_LIST.map(bank => ({ label: bank.shortName, value: bank.shortName }))} // Danh sách các ngân hàng
                                            value={this.state.selectedBank} // Giá trị được chọn
                                            onChange={this.handleSelectBank} // Hàm xử lý khi chọn ngân hàng
                                            placeholder="Chọn ngân hàng"
                                            isSearchable={true} // Cho phép tìm kiếm
                                            className="bank-dropdown"
                                        />
                                    </InputGroup>
                                </Row>
    
                                <Row>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text>SỐ TÀI KHOẢN</InputGroup.Text>
                                        <Form.Control
                                            value={payment.account_number}
                                            onChange={this.handleChangeAccountNumber}
                                        />
                                    </InputGroup>
                                </Row>
                                <Row>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text>SỐ TIỀN</InputGroup.Text>
                                        <Form.Control
                                            value={_formatCurrency(payment.amount)}
                                            disabled={true}
                                        />
                                    </InputGroup>
                                </Row>
                                <Row>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text>NỘI DUNG</InputGroup.Text>
                                        <Form.Control
                                            value={message}
                                            onChange={this.handleChangeMessage}
                                        />
                                    </InputGroup>
                                </Row>
                                {this.props.paymentFilter != CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ALL &&
                                <Row className="mt-2">
                                    <Col md="12" className="mb-2 text-center">
                                    {this.props.paymentFilter != CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ERROR 
                                    && this.state.payment.status != CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_TOOL
                                    && this.state.payment.status != CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_EXCHANGE
                                    && <Button
                                            className="btn-fill pull-right ml-3 btn-lg"
                                            type="submit"
                                            variant="danger"
                                            onClick={this.handleSubmitError}
                                            style={{ "padding": "5px" }}
                                        >
                                            Lỗi
                                        </Button>}
    
                                        { this.state.payment.status != CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_2
                                        && <Button
                                            className="btn-fill pull-right ml-3 btn-lg"
                                            type="submit"
                                            variant="light"
                                            onClick={this.handleSubmit}
                                            style={{ "padding": "5px" }}
                                        >
                                            {this.props.paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID? "Reset": "Lưu"}
                                        </Button>}
    
                                        {this.props.paymentFilter != CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID &&
                                        <Button
                                            className="btn-fill pull-right ml-3 btn-lg"
                                            type="submit"
                                            variant="primary"
                                            onClick={this.handleConfirmPayment}
                                            style={{ "padding": "5px" }}
                                        >
                                            Xác Nhận
                                        </Button>}
    
                                        <Button
                                            className="btn-fill pull-right ml-3 btn-lg"
                                            type="submit"
                                            variant="info"
                                            onClick={this.toggleShowQR}
                                            style={{ "padding": "5px" }}
                                        >
                                            {this.state.showQR? "Ẩn QR": "Hiện QR"}
                                        </Button>
                                    </Col>
                                </Row>}
                            </Col>
                            <Col md="3 p-2">
                                <div className="qr-container" ref={this.qrContainerRef}>
                                    {this.state.showQR && <QRCodeCanvas value={this.renderQR(payment, message)} size={size} />}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        } catch {
            return <Row className="payment-item">
                    <Col md="5" className="extra-infos">
                        <Row>
                            <Col sm="6"><b>Order ID</b></Col>
                            {payment.extra_infos.map((info, index) => {
                                if (info.includes("Search bank"))
                                    return "";
    
                                var element = (index % 2 == 1) ?
                                    <b>{info}</b> : info;
    
                                // if (index > 0)
                                return <Col sm="6" className="word-break">{element}</Col>
                            })}
                        </Row>
                        <Row>
                            <Col sm="6" className="mb-0"><b>Thời gian quét Order ID</b></Col>
                            <Col sm="6" className="mb-0">{formatTime(payment.timestamp_step1)}</Col>
                            <Col sm="6" className="mb-0"><b>Thời gian quét Thông tin CK</b></Col>
                            <Col sm="6" className="mb-0">{formatTime(payment.timestamp_step2)}</Col>
                            <Col sm="6" className="mb-0"><b>Thời gian Chuyển tiền</b></Col>
                            <Col sm="6" className="mb-0">{formatTime(payment.timestamp_step3)}</Col>
                            <Col sm="6" className="mb-0"><b>Thời gian Xác nhận thanh toán</b></Col>
                            <Col sm="6" className="mb-0">{formatTime(payment.timestamp_step4)}</Col>
                        </Row>
                        <Row>
                            <Col md="6">{this.renderStatus(payment)}</Col>
                        </Row>
                        <Row>
                            <p className="order-link">
                                <a href={payment.link} style={{color: "blue"}}>{payment.link}</a>
                            </p>
                        </Row>
                    </Col>

                    <Col md="7">
                            <Row className="mt-2">
                                <h3 className="mt-2 text-red text-center">Lỗi dữ liệu hoặc Lệnh đã hoàn tất</h3>
                            </Row>

                            {this.props.paymentFilter != CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ALL &&
                                <Row className="mt-2">
                                    <Col md="12" className="mb-2 text-center">
                                    {this.props.paymentFilter != CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ERROR 
                                    && this.state.payment.status != CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_TOOL
                                    && this.state.payment.status != CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_EXCHANGE
                                    && <Button
                                            className="btn-fill pull-right ml-3 btn-lg"
                                            type="submit"
                                            variant="danger"
                                            onClick={this.handleSubmitDelete}
                                            style={{ "padding": "5px" }}
                                        >
                                            Xóa
                                        </Button>}
    
                                        {this.props.paymentFilter != CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID &&
                                        <Button
                                            className="btn-fill pull-right ml-3 btn-lg"
                                            type="submit"
                                            variant="primary"
                                            onClick={this.handleConfirmPayment}
                                            style={{ "padding": "5px" }}
                                        >
                                            Xác Nhận
                                        </Button>}
                                    </Col>
                                </Row>}
                    </Col>
                </Row>
        }
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {
    updateAutoP2PPayment
})(AutoP2PPayment);