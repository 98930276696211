// import { toast } from "react-toastify";
import React from "react";
// import { data } from "jquery";
import { TYPES } from "./types";
import { authenService } from "../services"; 
// eslint-disable-next-line no-unused-vars
// import { CONFIGS } from "../configs";
// import CustomToastIndex from "../views/extras/customToast";
// import { getTranslate, LANGUAGE_KEYS } from "../utils";

export const login = (username, password) => async (dispatch) => {
  try {
    console.log("authenService Login");
    const result = await authenService.login(username, password);
    const data = result.data;
    console.log(result, data);
    
    if (data) {
      console.log("Success");
      dispatch({
        type: TYPES.ACTION_LOGIN_SUCCESS,
        payload: {
          user: { username: data.data.username },
          token: data.data.token
        }
      });
    } else {
      dispatch({
        type: TYPES.ACTION_LOGIN_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const getUser = () => async (dispatch) => {
  try {
    const result = await authenService.getUser();
    const data = result.data;
    console.log("getUser", result, data);
    
    if (data.data) {
      dispatch({
        type: TYPES.ACTION_GET_USER_SUCCESS,
        payload: {
          user: { username: data.data.username },
          token: data.data.token
        }
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_USER_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
};

export const authenActions = {
  login,
  getUser
};
