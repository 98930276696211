// import { LANGUAGE_KEYS, getTranslate, getCurrentLanguage } from "../utils";

export const CONFIGS = {
  FLAGS: {
    SELECTED_TIME_DURATION: "SELECTED_TIME_DURATION",
    UPDATE_CHART_COUNT: "UPDATE_CHART_COUNT",
    FINISH_GET_LISTINGS: "FINISH_GET_LISTINGS",
    FINISH_UPDATE_LISTINGS_HEYDATA: "FINISH_UPDATE_LISTINGS_HEYDATA",
    FINISH_GET_SHOPS: "FINISH_GET_SHOPS",

    TOAST_MESSAGE: "TOAST_MESSAGE",
    CURRENT_POPUP_LISTING_ID: "CURRENT_POPUP_LISTING_ID",

    SET_SHOP_TOTAL_SOLD: "SET_SHOP_TOTAL_SOLD"
  },

  TASK_STATUS: {
    DELETED: -2,
    ERROR: -1,
    SCHEDULE: 0,
    IN_PROGESS: 1,
    FINISH: 2
  },

  COLLECTION_TYPES: {
    FAVORITES: 'FAVORITES',
    USER_CREATED: 'USER_CREATED',
    GENERATED: 'GENERATED'
  },

  VIEW_MODE_SHOP_SOLD: {
    TODAY: "TODAY",
    YESTERDAY: "YESTERDAY",
    LAST_7_DAYS: "LAST_7_DAYS",
    LAST_14_DAYS: "LAST_14_DAYS",
    LAST_30_DAYS: "LAST_30_DAYS",
    BLACK_FRIDAY: "BLACK_FRIDAY"
  },

  AUTOP2P_PAYMENT_OPTIONS: {
    "ORDER_ID": "ORDER_ID",
    "TEMPLATE_1": "TEMPLATE_1",
    "TEMPLATE_2": "TEMPLATE_2",
    "CUSTOM": "CUSTOM"
  },

  AUTOP2P_PAYMENT_MESSAGES: [
    "HOANG VAN TON ck",
    "HOANG VAN TON ck"
  ],

  AUTOP2P_PAYMENT_QR_FILTER: {
		ALL: 'ALL',
    NEED_CRAWL_DATA: 'NEED_CRAWL_DATA',
    NOT_PAID: 'NOT_PAID',
    CONFIRM_PAID: 'CONFIRM_PAID',
    ERROR: 'ERROR'
	},

  AUTOP2P_EXCHANGE_NAMES: {
		BINANCE: "BINANCE"
},

	AUTOP2P_PAYMENT_TYPES: {
		BUY: "BUY",
		SELL: "SELL"
	},

	AUTOP2P_PAYMENT_STATUS: {
    ERROR_DELETE: -20,
		ERROR_INPUT: -10,
		ERROR_USER_PAYMENT: -11,
		
		NEW_1: 10,
		NEW_2: 11,
		EDITED: 12,

		CONFIRMED_ON_TOOL: 20,
		CONFIRMED_ON_EXCHANGE: 21,

    CONFIRMED_CUSTOMER_PAYMENT: 30
	}
}