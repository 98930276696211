/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';
import * as uuid from "uuid";

// import AdminNavbar from "components/Navbars/AdminNavbar";
// import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import LoginForm from 'components/LoginForm';
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import ScrollToTop from "react-scroll-to-top";

import routes from "routes.js";

import sidebarImage from "assets/img/sidebar-3.jpg";
import { Component } from 'react';
import { connect } from "react-redux";
import { getUser } from '../actions';

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      showModalLogin: false,
      color: 'black',
      hasImage: true,
      image: sidebarImage
    };

    // this.mainPanel = React.useRef(null);
  }

  // const [image, setImage] = React.useState(sidebarImage);
  // const [color, setColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  // const location = useLocation();
  // const 

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={uuid.v4()}
          />
        );
      } else {
        return null;
      }
    });
  };

  componentDidMount(){
    this.props.getUser();
  }

  componentWillReceiveProps(props) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.mainPanel.current.scrollTop = 0;

    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }

  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainPanel.current.scrollTop = 0;
  //   if (
  //     window.innerWidth < 993 &&
  //     document.documentElement.className.indexOf("nav-open") !== -1
  //   ) {
  //     document.documentElement.classList.toggle("nav-open");
  //     var element = document.getElementById("bodyClick");
  //     element.parentNode.removeChild(element);
  //   }
  // }, [location]);

  render() {
    const viewSoldChart = window.location.hostname.includes("resin");

    return (
      <>{!this.props.user? <LoginForm />:
        <div className="wrapper">
          
          {!viewSoldChart && <Sidebar color={this.state.color} image={this.state.hasImage ? this.state.image : ""} routes={routes} />}
          <div className="main-panel" ref={this.mainPanel} style={viewSoldChart? {width: "100%"}: {}}>
            {/* <AdminNavbar /> */}
            <div className="content">
              <Switch>{this.getRoutes(routes)}</Switch>
              {/* <ScrollToTop smooth /> */}
            </div>
            {/* <Footer /> */}
          </div>
        </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authenReducer.user
  };
};

export default connect(mapStateToProps, { getUser })(Admin);
