import { TYPES } from "./types";
import { collectionService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getAllCollections = () => async (dispatch) => {
  try {
    const result = await collectionService.getAllCollections();
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_COLLECTIONS_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_COLLECTIONS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const addNewCollection = (collectionName) => async (dispatch) => {
  try {
    const result = await collectionService.addNewCollection(collectionName);
    const data = result.data.data;

    console.log('data', data);
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_ADD_NEW_COLLECTION_SUCCESS,
        payload: { data: data[0] },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_ADD_NEW_COLLECTION_FAIL,
        payload: {},
      });
    }
  } 
  
  catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const toggleListingInCollection = (listing, collectionId) => async (dispatch) => {
  try {
    const result = await collectionService.toggleListingInCollection(listing, collectionId);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_TOGGLE_LISTING_IN_COLLECTION_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_TOGGLE_LISTING_IN_COLLECTION_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const updateCollection = (collection) => async (dispatch) => {
  try {
    const result = await collectionService.updateCollection(collection);
    const data = result.data.data[0];
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_UPDATE_COLLECTION_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_UPDATE_COLLECTION_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}


export const collectionAction = {
  getAllCollections,
  addNewCollection,
  toggleListingInCollection
}
