/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as moment from 'moment'
import AutoP2PPayment from "../components/AutoP2PPayment";
import { QRCodeCanvas } from "qrcode.react"
import { getAutoP2PPayments } from "../actions"
import { CONFIGS } from '../configs'
import { _formatCurrency } from '../utils'

import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";
import { basename } from "path";

class AutoP2P extends Component {
  constructor() {
    super();

    this.state = {
      currentIndex: 0,
      autoP2PPayments: [],
      timestampGet: 0,
      paymentType: [],
      paymentOption: localStorage.getItem('autop2p_payment_option') ? localStorage.getItem('autop2p_payment_option') : CONFIGS.AUTOP2P_PAYMENT_OPTIONS.ORDER_ID,
      paymentFilter: localStorage.getItem('autop2p_payment_filter') ? localStorage.getItem('autop2p_payment_filter') : CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID
    }

    this.renderCount = 0;
  }

  componentDidMount() {
    var paymentType = window.location.href.includes("autop2p-buy")? CONFIGS.AUTOP2P_PAYMENT_TYPES.BUY: CONFIGS.AUTOP2P_PAYMENT_TYPES.SELL;
    var { paymentFilter } = this.state;

    this.setState({ paymentType });

    this.props.getAutoP2PPayments({ paymentType, paymentFilter })
    // this.interval = setInterval(() => {
    //   this.props.getAutoP2PPayments()
    // }, 10000);
  }

  componentWillReceiveProps(props) {
    console.log("@@@ componentWillReceiveProps", props.autoP2PPayments)
    // if (JSON.stringify(props.autoP2PPayments) != JSON.stringify(this.state.autoP2PPayments))
    this.setState({ autoP2PPayments: props.autoP2PPayments, timestampGet: props.timestampGet })
  }


  handleSelectPaymentOption = (e) => {
    localStorage.setItem('autop2p_payment_option', e.target.value);
    this.setState({ paymentOption: e.target.value });
  }

  renderPaymentOption = () => {
    var { paymentOption } = this.state;
    return (
      <div>
        <div className="payment_option d-flex flex-column flex-md-row">
          <div className="mr-2"><b>Nội dung chuyển khoản:</b></div>
          <Form.Check
            type="radio"
            label="Order ID"
            value={CONFIGS.AUTOP2P_PAYMENT_OPTIONS.ORDER_ID}
            name="group1"
            onChange={this.handleSelectPaymentOption}
            checked={paymentOption === CONFIGS.AUTOP2P_PAYMENT_OPTIONS.ORDER_ID}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="Mẫu 1"
            value={CONFIGS.AUTOP2P_PAYMENT_OPTIONS.TEMPLATE_1}
            name="group1"
            onChange={this.handleSelectPaymentOption}
            checked={paymentOption === CONFIGS.AUTOP2P_PAYMENT_OPTIONS.TEMPLATE_1}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="Mẫu 2"
            value={CONFIGS.AUTOP2P_PAYMENT_OPTIONS.TEMPLATE_2}
            name="group1"
            onChange={this.handleSelectPaymentOption}
            checked={paymentOption === CONFIGS.AUTOP2P_PAYMENT_OPTIONS.TEMPLATE_2}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="Tùy chỉnh"
            value={CONFIGS.AUTOP2P_PAYMENT_OPTIONS.CUSTOM}
            name="group1"
            onChange={this.handleSelectPaymentOption}
            checked={paymentOption === CONFIGS.AUTOP2P_PAYMENT_OPTIONS.CUSTOM}
            className="me-4"
        />
        </div>
      </div>
    )
  }

  handleSelectPaymentFilter = (e) => {
    localStorage.setItem('autop2p_payment_filter', e.target.value);

    this.setState({ currentIndex: 0, paymentFilter: e.target.value }, 
      () => {
        this.getUpdatedData();
      });

    // window.location.reload();
  }

  renderPaymentFilter = () => {
    var { paymentFilter } = this.state;

    return (
      <div>
        <div className="payment_option d-flex flex-column flex-md-row">
          <div className="mr-2"><b>Trạng thái:</b></div>
          {/* <Form.Check
            type="radio"
            label="Chưa quét dữ liệu"
            value={CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NEED_CRAWL_DATA}
            name="group2"
            onChange={this.handleSelectPaymentFilter}
            checked={paymentFilter === CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NEED_CRAWL_DATA}
            className="me-4"
          /> */}

          <Form.Check
            type="radio"
            label="Chưa thanh toán"
            value={CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID}
            name="group2"
            onChange={this.handleSelectPaymentFilter}
            checked={paymentFilter === CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="Đã thanh toán"
            value={CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID}
            name="group2"
            onChange={this.handleSelectPaymentFilter}
            checked={paymentFilter === CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="Lỗi"
            value={CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ERROR}
            name="group2"
            onChange={this.handleSelectPaymentFilter}
            checked={paymentFilter === CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ERROR}
            className="me-4"
          />

          <Form.Check
            type="radio"
            label="Tất cả"
            value={CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ALL}
            name="group2"
            onChange={this.handleSelectPaymentFilter}
            checked={paymentFilter === CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ALL}
            className="me-4"
          />
        </div>
      </div>
    )
  }

  handleConfirmPayment = (currentIndex) => {
    this.setState({ currentIndex })
  }

  getUpdatedData = () => {
    console.log("@@@ getUpdatedData")
    var { paymentType, paymentFilter } = this.state;

    this.props.getAutoP2PPayments({ paymentType, paymentFilter });
  }

  render() {
    var { paymentFilter, currentIndex, autoP2PPayments } = this.state;
    this.renderCount ++;

    console.log("XXXX renderCount", this.renderCount);

    if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NEED_CRAWL_DATA)
      autoP2PPayments = autoP2PPayments.filter(p => p.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_1);
    else if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID) {
      autoP2PPayments = autoP2PPayments.filter(p => p.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.NEW_2 || p.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.EDITED);
      
      var durationGet = Date.now() - this.state.timestampGet; 
      console.log("XXXX timestampGET", this.state.timestampGet, Date.now(), durationGet)

      if (autoP2PPayments.length == 0 && Date.now() - this.state.timestampGet < 1000) {
        setTimeout(() => {
          if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID)
            // window.location.reload();
          this.getUpdatedData();
        }, 5000);
      }
    } else if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.CONFIRM_PAID)
      autoP2PPayments = autoP2PPayments.filter(p => p.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_TOOL || p.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_EXCHANGE);
      
    else if (paymentFilter == CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.ERROR)
      autoP2PPayments = autoP2PPayments.filter(p => p.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.ERROR_INPUT);

    // if (paymentFilter != CONFIGS.AUTOP2P_PAYMENT_QR_FILTER.NOT_PAID)
    //   currentIndex = 0;

    console.log("@@@ paymentFilter", paymentFilter, autoP2PPayments.length);

    return (
      <>
        <Container fluid id="autop2p-qr-page">
          <Row className="mb-4">
            <h2 className="text-center">AUTOP2P - QR</h2>
          </Row>

          <Row>
            <Col md="6" className="d-flex d-md-block justify-content-right align-items-right view-mode-option">
              {this.renderPaymentOption()}
            </Col>
            <Col md="6" className="d-flex d-md-block justify-content-right align-items-right view-mode-option">
              {this.renderPaymentFilter()}
            </Col>
          </Row>

          {/* {payment && <AutoP2PPayment
            payment={payment}
            paymentOption={this.state.paymentOption}
            currentIndex={this.state.currentIndex}
            handleConfirmPayment={this.handleConfirmPayment}
          />} */}

          {currentIndex < autoP2PPayments.length 
          && autoP2PPayments.map((payment, index) =>
            <AutoP2PPayment
              hideQR={index > 0 || payment.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_TOOL || payment.status == CONFIGS.AUTOP2P_PAYMENT_STATUS.CONFIRMED_ON_EXCHANGE}
              payment={payment}
              paymentOption={this.state.paymentOption}
              paymentFilter={this.state.paymentFilter}
            />
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    autoP2PPayments: state.autoP2PReducer.autoP2PPayments,
    timestampGet: state.autoP2PReducer.timestampGet
  }
}

export default connect(mapStateToProps, {
  getAutoP2PPayments
})(AutoP2P);