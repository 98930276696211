/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import * as moment from 'moment'
import ListingItem from '../components/ListingItem'
import SoldChart from "../components/SoldChart";
import { 
  getListings, 
  setFlag, 
  updateListingsHeyData, 
  postListings, 
  getAllTasks,
  getAllCollections,
  addNewCollection,
  toggleListingInCollection,
  getListing30DaysSold,
  updateCollection
} from '../actions';
// import ScrollToTop from "react-scroll-to-top";
import Pagination from "../components/Pagination";
import ScrollUpButton from 'react-scroll-up-button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "../configs";
import queryString from 'query-string';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    InputGroup,
    Collapse,
    Modal,
    Table
  } from "react-bootstrap";

import { FiEdit } from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";

class CollectionDetail extends Component {
  constructor() {
    super();
    this.state = {
      listings: [],
      collections: [],
      newCollectionName: "",

      isDownloading: false,
      showModalListingSold: false,
      currentListing: null,
      firstTimeLoading: true,
      showFilterOptions: true,
      showModalEditCollection: false,
      collectionName: '',

      currentCollection: null,

      totalCount: 0,

      outdateListingIds: [],
      forceUpdateHeyData: false,
      query: this.initQuery,
      
      isShowTitle: true,
      isShowTags: localStorage.getItem('c_isShowTags') == 'false'? false : true,
      isShowViews: localStorage.getItem('c_isShowViews') == 'false'? false : true,
      isShowFavorites: localStorage.getItem('c_isShowFavorites') == 'false'? false : true,
      isShowFavoriteRate: localStorage.getItem('c_isShowFavoriteRate') == 'false'? false : true,
      isShowCreated: localStorage.getItem('c_isShowCreated') == 'false'? false : true,
      isShowCheckHey: localStorage.getItem('c_isShowCheckHey') == 'false'? false : true,
      showFilterOptions: localStorage.getItem('c_showFilterOptions') == 'false'? false : true
    }
  }

  initQuery = {
    itemsPerPage: 24,
    itemOffset: 0,
    currentPage: 1,
    priceMin: 1,
    priceMax: 500,
    isUpdateQuery: true,
    dailyViews: 30,
    sold30DaysCalculate: 10,
    revenue30DaysCalculate: 10,
    sold30DaysCount: 10,
    revenue30DaysCount: 10,
    lastUpdate: 10000,
    sortBy: '-created_at',
    sortOrder: 1,
    favoriteRate: 1,
    keyword: '',
  }

  updateUrl = (query) => {
    const { history } = this.props;
    const queryStr = queryString.stringify(query);

    history.push({
      pathname: `/admin/collection/${this.state.query.collectionId}`,
      search: queryStr,
    });
  }

  updatePage = (itemOffset, newPage) => {
    console.log("updatePage", newPage, this.state.currentPage);

    if (newPage == this.state.currentPage)
        return;

    this.setState({ 
      query: {...this.state.query, itemOffset: itemOffset, currentPage: newPage},
      forceUpdateHeyData: false
    }, () => {
      this.setState({ listing: [], isUpdateQuery: false }, () => {
        this.updateUrl(this.state.query)
        this.props.getListings(this.state.query);
      })
    })
  }

  handleChangeKeyword= (e) => {
    this.setState({ 
      query: {...this.state.query, keyword: e.target.value }
    })
  }

  handleChangeDailyViews= (e) => {
    this.setState({ 
      query: {...this.state.query, dailyViews: e.target.value }
    })
  }

  handleChangeFavoriteRate= (e) => {
      this.setState({ 
        query: {...this.state.query, favoriteRate: e.target.value }
      })
  }

  handleChangeSold30DaysCalculate= (e) => {
    this.setState({ 
      query: {...this.state.query, sold30DaysCalculate: e.target.value }
    })
  }

  handleChangeRevenue30DaysCalculate= (e) => {
    this.setState({ 
      query: {...this.state.query, revenue30DaysCalculate: e.target.value }
    })
  }

  handleChangeSold30DaysCount= (e) => {
    this.setState({ 
      query: {...this.state.query, sold30DaysCount: e.target.value }
    })
  }

  handleChangeRevenue30DaysCount= (e) => {
    this.setState({ 
      query: {...this.state.query, revenue30DaysCount: e.target.value }
    })
  }

  handleSelectSort = (e) => {
    this.setState({ 
      query: {...this.state.query, sortBy: e.target.value }
    })
  }

  handleSelectSortOrder = (e) => {
    this.setState({ 
      query: {...this.state.query, sortOrder: parseInt(e.target.value) }
    })
  }

  handleSelectLastUpdate = (e) => {
    this.setState({ 
      query: {...this.state.query, lastUpdate: e.target.value }
    })
  }

  handleSelectTask = (e) => {
    e.preventDefault();

    this.setState({ 
      query: {...this.state.query, taskId: e.target.value }
    }, () => {
      this.updateUrl(this.state.query)
      this.props.getListings(this.state.query);
      localStorage.setItem('c_query', JSON.stringify(this.state.query))
    })
  }

  handleChangePriceMin= (e) => {
    this.setState({ 
      query: {...this.state.query, priceMin: e.target.value }
    })
  }

  handleChangePriceMax= (e) => {
    this.setState({ 
      query: {...this.state.query, priceMax: e.target.value }
    })
  }

  handleChangeDateFrom = (e) => {
    var dateFrom = e.target.value;
    console.log('dateFrom', dateFrom);

    this.setState({ 
      query: {
        ...this.state.query, dateFrom
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('handleSubmit');
    this.updateUrl(this.state.query)
    this.props.getListings(this.state.query);
    localStorage.setItem('c_query', JSON.stringify(this.state.query))
  }

  handleToggleShowTags = (e) => {
    this.setState({
      isShowTags: !this.state.isShowTags
    })

    localStorage.setItem('c_isShowTags', !this.state.isShowTags);
  }

  handleToggleShowViews = (e) => {
    this.setState({
      isShowViews: !this.state.isShowViews
    })

    localStorage.setItem('c_isShowViews', !this.state.isShowViews);
  }

  handleToggleShowFavorites = (e) => {
    this.setState({
      isShowFavorites: !this.state.isShowFavorites
    })

    localStorage.setItem('c_isShowFavorites', !this.state.isShowFavorites);
  }

  handleToggleShowFavoriteRate = (e) => {
    this.setState({
      isShowFavoriteRate: !this.state.isShowFavoriteRate
    })

    localStorage.setItem('c_isShowFavoriteRate', !this.state.isShowFavoriteRate);
  }

  handleToggleShowCreated = (e) => {
    this.setState({
      isShowCreated: !this.state.isShowCreated
    })

    localStorage.setItem('c_isShowCreated', !this.state.isShowCreated);
  }

  handleToggleShowCheckHey = (e) => {
    this.setState({
      isShowCheckHey: !this.state.isShowCheckHey
    })

    localStorage.setItem('c_isShowCheckHey', !this.state.isShowCheckHey);
  }

  needUpdateHeyData = (listing) => {
    // "2023-07-30T18:15:22.912Z"
    var lastCheckHey = moment(listing.last_check_hey);
    var today = moment();

    return !lastCheckHey.isSame(today, 'day');
  }

  handleUpdateHeyData = (e) => {
    e.preventDefault();
    console.log('handleUpdateHeyData');
    this.setState({ forceUpdateHeyData: true });
    this.props.updateListingsHeyData(this.state.listings.map(listing => listing.listing_id));
  }

  handleResetSearch = (e) => {
    e.preventDefault();
    this.setState({
      query: this.initQuery
    })
  }

  handleExportCSV = async (e) => {
    e.preventDefault();

    this.setState({ isDownloading: true });
    
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${process.env.REACT_APP_API_URL}/api/listing/export-csv`, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        const blob = xhr.response;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export.csv';
        link.click();
      }

      xhr.send();
      this.setState({ isDownloading: false });
    } catch (error) {
      console.error('Error:', error);
      this.setState({ isDownloading: false });
    }
  }

  componentDidMount() {
      const queryStringFromUrl = window.location.search;
      const urlParams = new URLSearchParams(queryStringFromUrl);
      var itemsPerPage = urlParams.get('itemsPerPage');

      var collectionId = this.props.match.params.id;

      if (itemsPerPage) {
        const queryObject = queryString.parse(queryStringFromUrl);
        
        ['itemsPerPage', 'itemOffset', 'currentPage'].map(key => {
          queryObject[key] = parseInt(queryObject[key])
        })

        queryObject.itemOffset = (queryObject.currentPage - 1) * queryObject.itemsPerPage;
        
        this.setState({
          query: { ...queryObject, collectionId }
        }, () => {
          this.props.getAllTasks();
          this.props.getListings(this.state.query);
        })
      } else {
        this.props.getAllTasks();
        this.props.getAllCollections();

        var query = JSON.parse(localStorage.getItem('c_query'));

        if (query) {
          this.setState({
            query: {...this.initQuery,
              'priceMin': query['priceMin'] | 0,
              'priceMax': query['priceMax'] | 0,
              'dailyViews': query['dailyViews'] | 0,
              'sold30DaysCalculate': query['sold30DaysCalculate'] | 0,
              'revenue30DaysCalculate': query['revenue30DaysCalculate'] | 0,
              'sold30DaysCount': query['sold30DaysCount'] | 0,
              'revenue30DaysCount': query['revenue30DaysCount'] | 0,
              'lastUpdate': query['lastUpdate'] | 100000,
              'sortBy': query['sortBy'],
              'favoriteRate': query['favoriteRate'] | 0,
              'keyword': query['keyword'],
              'sortOrder': query['sortOrder'] | 0,
              collectionId
            }
          }, () => {
            this.props.getListings(this.state.query);
          })
        }
      }
  }

  componentWillReceiveProps(props) {
    var outdateListingIds = props.listings.filter(listing => (this.needUpdateHeyData(listing))).map(listing => listing.listing_id)
    console.log("outdateListingIds", outdateListingIds);
  
    // new data & not update yet
    if (!props.updateHey && outdateListingIds.length > 0) {
      this.props.updateListingsHeyData(outdateListingIds)
      this.setState({ outdateListingIds })
    }

    if (!this.props.updateHey && props.updateHey) {
      var listings = this.state.forceUpdateHeyData? props.listings
                        :props.listings.filter(listing => this.state.outdateListingIds.indexOf(listing.listing_id) >= 0)

      this.props.postListings(listings);
    }

    var { listings, totalCount, updateHey, toastMessage, collections, favorite, currentPopupListingId } = props;

    if (listings.length > 0 && this.state.listings.length == 0) {
      const queryStringFromUrl = window.location.search;
      const urlParams = new URLSearchParams(queryStringFromUrl);
      var popup_listing_id = urlParams.get('popup_listing_id');

      if (popup_listing_id) {
        var currentListing = listings.filter(listing => listing.listing_id == popup_listing_id);
        currentListing = currentListing.length > 0? currentListing[0]: null;
  
        if (currentListing)
          this.handleShowModalListingSold(currentListing);
      }
    }

    if (favorite && !this.state.favorite) {
      var collectionId = this.props.match.params.id;
      
      if (collectionId == favorite._id)
        this.setState({ currentCollection: favorite });
    }
    
    if (collections.length > 0 && this.state.collections.length == 0) {
      var collectionId = this.props.match.params.id;
      var currentCollections = collections.filter(collection => collection._id == collectionId);

      if (currentCollections.length > 0)
        this.setState({ currentCollection: currentCollections[0] });
    }

    this.setState({ listings, totalCount, updateHey, toastMessage, collections, favorite, currentPopupListingId })
  }

  handleClick(e) {
    const el = (sel, par) => (par||document).querySelector(sel);
    const elPopup = el("#popup-add-to-collection");
    Object.assign(elPopup.style, {
      display: `none`
    });
  }

  toggleCheckboxCollection(e, listingId, collectionId) {
    e.preventDefault();
    e.stopPropagation();
    var listing = this.state.listings.filter(listing => listing.listing_id == listingId)[0];
    this.props.toggleListingInCollection(listing, collectionId);
  }

  handleChangeCollectionName = (e) => {
    e.stopPropagation();
    this.setState({ newCollectionName: e.target.value });
  }

  addNewCollection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.addNewCollection(this.state.newCollectionName);
  }

  renderCheckboxCollections = () => {
    if (!this.state.currentPopupListingId)
      return <></>;

    return <Form>
      {this.state.collections.map(collection => <>
        <Form.Check
        type='checkbox'
        label={collection.name}
        checked={collection.listing_ids && collection.listing_ids.indexOf(this.state.currentPopupListingId) >= 0}
        onClick={e => this.toggleCheckboxCollection(e, this.state.currentPopupListingId, collection._id)}
      />
      </>)}
    </Form>
  }

  handleShowModalListingSold = (listing) => {
    this.setState({
      showModalListingSold: true,
      currentListing: listing,
      query: {
        ...this.state.query,
        popup_listing_id: listing.listing_id
      }
    });

    this.props.getListing30DaysSold(listing.listing_id);

    const queryStringFromUrl = window.location.search;
    const urlParams = new URLSearchParams(queryStringFromUrl);
    var itemsPerPage = urlParams.get('itemsPerPage');
    urlParams.set('popup_listing_id', listing.listing_id);

    console.log('urlParams', urlParams.toString(), itemsPerPage)

    var currentURL = window.location.protocol + "//" 
      + window.location.host 
      + window.location.pathname 
      + (itemsPerPage? '?': '?')
      + urlParams.toString();
      
		window.history.pushState('', '', currentURL);
  }

  handleCloseModalListingSold = (e) => {
    this.setState({
      showModalListingSold: false,
      query: {
        ...this.state.query,
        popup_listing_id: ''
      }
    });

    // const queryStringFromUrl = window.location.search;
    // const urlParams = new URLSearchParams(queryStringFromUrl);
    // urlParams.set('popup_listing_id', '');

    // var currentURL = window.location.protocol + "//" 
    //   + window.location.host 
    //   + window.location.pathname 
    //   + (queryStringFromUrl? '': '?')
    //   + urlParams.toString();
      
		// window.history.pushState({ path: currentURL }, '', currentURL);
  }

  handleCopyText(text) {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }

    toast.success(`Copied to clipboard!`, {
      position: "bottom-right",
      autoClose: 500,
      hideProgressBar: true
    });
  }

  renderCustomBadge(style, className, text) {
    return <Badge key={text} pill 
      bg={style}
      className={className}
      onClick={() => this.handleCopyText(text)}
      title="Click to copy"
    >
      {text}
    </Badge>
  }

  toggleShowFilterOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      showFilterOptions: !this.state.showFilterOptions
    });

    localStorage.setItem('c_showFilterOptions', !this.state.showFilterOptions);
  }

  handleEditCollectionName = (e) => {
    e.preventDefault();

    this.setState({
      currentCollection: {
        ...this.state.currentCollection,
        name: e.target.value
      }
    })
  }

  handleEditCollectionDescription = (e) => {
    e.preventDefault();

    this.setState({
      currentCollection: {
        ...this.state.currentCollection,
        description: e.target.value
      }
    })
  }

  saveCollection = (e) => {
    e.preventDefault();
    this.props.updateCollection(this.state.currentCollection);

    toast.success('Save collection successfully');
    this.setState({ showModalEditCollection: false });
  }

  render() {
    console.log("Render", this.state);
    var currentListing = this.state.currentListing;

    return (
    <>
      <Container fluid id="listing-page"
        onClick={this.handleClick}
      >
        <Row>
          <h2>
            {
              (this.state.currentCollection && this.state.currentCollection.name == '__FAVORITES__')?
                <>
                  FAVORITES
                  <AiFillHeart className="icon-favorite ml-2"/>
                </>: <>
                {this.state.currentCollection && this.state.currentCollection.name}
                <FiEdit 
                  className="ml-2 cursor-pointer"
                  style={{fontSize: "20px"}}
                  onClick={(e) => this.setState({ showModalEditCollection: true })}
                />
              </>
            }
          </h2>
        </Row>
        <Row>
          <h5>
            {this.state.currentCollection && this.state.currentCollection.description}
          </h5>
        </Row>

        <Card 
          id="popup-add-to-collection" 
          bg="light"
          border="dark"
          text="dark"
        >
          {/* <Card.Header className="text-center">Header</Card.Header> */}
          <Card.Title className="text-center mt-2 mb-2">Add to Collection</Card.Title>

          <Card.Body id="collections-list">
            {this.renderCheckboxCollections()}
          </Card.Body>

          <Card.Footer>
            <Form>
              <Form.Control 
                size="sm" 
                type="text" 
                placeholder="Collection Name" 
                id="input-add-new-collection"
                value={this.state.newCollectionName}
                onChange={this.handleChangeCollectionName}
                onClick={e => { e.stopPropagation(); }}
              />
              <Button
                className="btn-fill pull-right btn-sm"
                id="btn-add-new-collection"
                disabled={this.state.newCollectionName == ""}
                type="submit"
                variant="primary"
                onClick={this.addNewCollection}
              >
                Add
              </Button>
            </Form>
          </Card.Footer>
        </Card>

        {this.state.showFilterOptions && <>
          <Row>
            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Search</InputGroup.Text>
                <Form.Control
                  className='text-red'
                  value={this.state.query.keyword}
                  onChange={this.handleChangeKeyword}
                  placeholder="Enter keyword"
                  aria-label="keyword"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Daily Views ≥</InputGroup.Text>
                <Form.Control
                  defaultValue=""
                  value={this.state.query.dailyViews}
                  onChange={this.handleChangeDailyViews}
                  placeholder="Daily Views"
                  aria-label="daily_view"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Price Min ≥</InputGroup.Text>
                <Form.Control
                  value={this.state.query.priceMin}
                  onChange={this.handleChangePriceMin}
                  placeholder=""
                  aria-label="price-min"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Price Max ≤ </InputGroup.Text>
                <Form.Control
                  value={this.state.query.priceMax}
                  onChange={this.handleChangePriceMax}
                  placeholder=""
                  aria-label="price-max"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
          </Row>

          <Row className="">
            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Sold-30 Calculate ≥</InputGroup.Text>
                <Form.Control
                  value={this.state.query.sold30DaysCalculate}
                  onChange={this.handleChangeSold30DaysCalculate}
                  placeholder="Sold 30 Days Calculate"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Revenue-90 Calculate ≥</InputGroup.Text>
                <Form.Control
                  value={this.state.query.revenue30DaysCalculate}
                  onChange={this.handleChangeRevenue30DaysCalculate}
                  placeholder="Revenue 30 Days Calculate"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Sold-30 Count ≥</InputGroup.Text>
                <Form.Control
                  value={this.state.query.sold30DaysCount}
                  onChange={this.handleChangeSold30DaysCount}
                  placeholder="Sold 30 Days Count"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Revenue-30 Count ≥</InputGroup.Text>
                <Form.Control
                  value={this.state.query.revenue30DaysCount}
                  onChange={this.handleChangeRevenue30DaysCount}
                  placeholder="Revenue 30 Days Count"
                />
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Favorite rate ≥</InputGroup.Text>
                <Form.Control
                  value={this.state.query.favoriteRate}
                  onChange={this.handleChangeFavoriteRate}
                  placeholder="Favorite rate"
                  aria-label="daily_view"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Last Update</InputGroup.Text>
                <Form.Select aria-label=""
                  value={this.state.query.lastUpdate}
                  onChange={this.handleSelectLastUpdate}
              >
                <option value="1000">All</option>
                <option value="1">1 day</option>
                <option value="2">2 days</option>
                <option value="3">3 days</option>
                <option value="4">4 days</option>
                <option value="5">5 days</option>
                <option value="6">6 days</option>
                <option value="7">1 week</option>
                <option value="14">2 weeks</option>
                <option value="21">3 weeks</option>
                <option value="30">1 month</option>
                </Form.Select>
              </InputGroup>
            </Col>

            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Sort by</InputGroup.Text>
                <Form.Select
                value={this.state.query.sortBy}
                onChange={this.handleSelectSort}
              >
                <option value="-daily_views">Daily Views</option>
                <option value="-views">Total Views</option>
                <option value="-sale_30_days_calculate">Sold-30 Calculate</option>
                <option value="-revenue_30_days_calculate">Revenue-30 Calculate</option>
                <option value="-sale_30_days_count">Sold-30 Count</option>
                <option value="-revenue_30_days_count">Revenue-30 Count</option>
                <option value="-price_usd">Price</option>
                <option value="last_modified_minutes">Last Update</option>
                <option value="-num_favorers">Favorites</option>
                <option value="-favorite_rate">Favorites Rate</option>
                <option value="original_creation_days">Created Date</option>
                <option value="-created_at">EIT create</option>
                <option value="-updated_at">EIT update</option>
                </Form.Select>
              </InputGroup>
            </Col>
            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Sort order</InputGroup.Text>
                <Form.Select
                value={this.state.query.sortOrder}
                onChange={this.handleSelectSortOrder}
              >
                <option value="1">Decrease</option>
                <option value="-1">Increase</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>

          <Row className="filter-group">
              <Col md="2">
                <Form>
                  <Form.Check
                    type='switch'
                    label="Tags/Categories"
                    checked={this.state.isShowTags}
                    onChange={this.handleToggleShowTags}
                  />

                  <Form.Check
                    type='switch'
                    label="Total Views"
                    checked={this.state.isShowViews}
                    onChange={this.handleToggleShowViews}
                  />
                </Form>
              </Col>

              <Col md="2">
                <Form>
                  <Form.Check
                    type='switch'
                    label="Favorites"
                    checked={this.state.isShowFavorites}
                    onChange={this.handleToggleShowFavorites}
                  />
                  <Form.Check
                    type='switch'
                    label="Favorite rate"
                    checked={this.state.isShowFavoriteRate}
                    onChange={this.handleToggleShowFavoriteRate}
                  />
                </Form>
              </Col>

              <Col md="2">
                <Form>
                  <Form.Check
                    type='switch'
                    label="Created"
                    checked={this.state.isShowCreated}
                    onChange={this.handleToggleShowCreated}
                  />

                  <Form.Check
                    type='switch'
                    label="Last check Hey"
                    checked={this.state.isShowCheckHey}
                    onChange={this.handleToggleShowCheckHey}
                  />
                </Form>
              </Col>

              <Col md="2">
              </Col>

              <Col md="4">
                {/* <InputGroup className="mb-3"> */}
                <div class='btn-group-listing'>
                  {/* <Button
                    className="btn-fill pull-right btn-sm"
                    type="submit"
                    variant="warning"
                    onClick={this.handleUpdateHeyData}
                    style={{ "padding": "5px" }}
                  >
                    Update Hey
                  </Button>

                  <Button
                    className="btn-fill pull-right ml-3 btn-sm"
                    type="submit"
                    variant="danger"
                    onClick={this.handleExportCSV}
                    style={{ "padding": "5px" }}
                    disabled={this.state.isDownloading}
                  >
                    {this.state.isDownloading? 'Downloading ...': 'Export CSV'}
                  </Button> */}

                  {/* <Button
                    className="btn-fill pull-right ml-3"
                    type="submit"
                    variant="light"
                    onClick={() => {this.handleResetSearch}}
                    style={{ "padding": "5px" }}
                  >
                    Clear
                  </Button> */}

                  <Button
                    className="btn-fill pull-right ml-3 btn-sm"
                    type="submit"
                    variant="primary"
                    onClick={this.handleSubmit}
                    style={{ "padding": "5px" }}
                  >
                    Search
                  </Button>
                </div>
                {/* </InputGroup> */}
              </Col>
            
          </Row>
        </>}

        <Row>
            <h5>
              <b>Found <span className='text-danger'>{this.state.totalCount}</span> listings:</b>
              <Badge bg="info" className="ml-2 cursor-pointer" onClick={this.toggleShowFilterOptions}>{this.state.showFilterOptions? "Hide Filter": "Show Filter"}</Badge>
            </h5>          
        </Row>
        

        <Row>
            {(this.state.listings.length > 0)?
            this.state.listings
            .map(listing => <Col md="3">
                <ListingItem 
                  key={listing.listing_id} 
                  handleShowModalListingSold={this.handleShowModalListingSold}
                  listing={listing} 
                  isShowTags={this.state.isShowTags}
                  isShowViews={this.state.isShowViews} 
                  isShowFavorites={this.state.isShowFavorites}
                  isShowFavoriteRate={this.state.isShowFavoriteRate}
                  isShowCreated={this.state.isShowCreated} 
                  isShowCheckHey={this.state.isShowCheckHey} 
                  isShowTitle={this.state.isShowTitle} 
                />
            </Col>)
            :<div class="loading">
                <FerrisWheelSpinner loading={true}　color="#17a2b8" />
            </div>}
        </Row>
      </Container>
      <Pagination totalItems = {this.state.totalCount} itemsPerPage = {this.state.query.itemsPerPage} itemOffset = {this.state.query.itemOffset} updatePage={this.updatePage}/>
      {/* {this.state.toastMessage && <>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
          {this.state.toastMessage}
        <ToastContainer />
      </>} */}
      <ToastContainer
        position="bottom-right"
        autoClose={500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

      <Modal size="xl" className="modal-listing-sold"
        show={this.state.showModalListingSold} 
        onHide={this.handleCloseModalListingSold}
        >
        <Modal.Header closeButton>
          {currentListing && <>
            <a href={`https://etsy.com/listing/${currentListing.listing_id}`} target="_blank" title='View this listing on Etsy'>
              {currentListing.title}
            </a>
            {this.renderCustomBadge("danger", "ml-2 cursor-pointer", currentListing.listing_id)}
          </>}
        </Modal.Header>
        <Modal.Body>
          {currentListing && <Row>
            <Col md="2">
              <a href={`https://etsy.com/listing/${currentListing.listing_id}`} target="_blank" title='View this listing on Etsy'>
                <Card.Img variant="top" src={currentListing.img_cover_url} />
              </a>
            </Col>
            <Col md="3">
              <Table>
                <tbody>
                  <tr>
                    <td>Price</td>
                    <td className="value">
                      <Badge pill bg="danger">
                      {currentListing.currency_symbol?currentListing.currency_symbol:'$'}
                      {currentListing.price_usd?`${currentListing.price_usd.toFixed(2)}`: '~'}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Daily views</td>
                    <td className="value">
                      <Badge pill bg="danger">
                        {currentListing.daily_views}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Created
                    </td>
                    <td className="value">
                      <Badge pill bg="info">
                      {currentListing.original_creation}
                      </Badge>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col md="3">
              <Table>
                <tbody>
                  <tr>
                    <td>
                      Favorite Rate
                    </td>
                    <td className="value">
                      <Badge pill bg="danger">
                      { currentListing.favorite_rate? currentListing.favorite_rate
                      : '~' }
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Sold-30 calculate</td>
                    <td className="value">
                      <Badge pill bg="info">
                        {currentListing.sale_30_days_calculate}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Sold-30 count</td>
                    <td className="value">
                      <Badge pill bg="success"
                        title="View 30 days sold chart"
                        className="cursor-pointer"
                        onClick={this.handleDisplaySold30Days}
                      >
                        {currentListing.public_sold? (currentListing.sale_30_days_count != null && currentListing.sale_30_days_count >= 0? currentListing.sale_30_days_count: 'Loading ...')
                          :'Not public'}
                      </Badge>
                    </td>
                  </tr>               
                </tbody>
              </Table>
            </Col>

            <Col md="4">
              <Table>
                <tbody>
                  <tr>
                    <td>Last update</td>
                    <td className="value">
                      <Badge pill bg="danger">
                        {currentListing.last_modified}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Revenue-30 calculate</td>
                    <td className="value">
                      <Badge pill bg="info">
                      ${currentListing.revenue_30_days_calculate? currentListing.revenue_30_days_calculate.toFixed(2): 0}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Revenue-30 count</td>
                    <td className="value">
                      <Badge pill bg="success">
                        ${currentListing.revenue_30_days_count? currentListing.revenue_30_days_count.toFixed(2): 0}
                      </Badge>
                    </td>
                  </tr>                  
                </tbody>
              </Table>
            </Col>
          </Row>}

          <Row>
            <SoldChart listing={currentListing} />
          </Row>
        </Modal.Body>
      </Modal>

      <Modal size="l" className="modal-edit-collection"
        show={this.state.showModalEditCollection} 
        onHide={(e) => this.setState({ showModalEditCollection: false })}
        >
        <Modal.Header closeButton>
          <h4>Edit Collection</h4>
          <hr/>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                type="input" 
                placeholder="Enter Collection Name" 
                value={this.state.currentCollection ? this.state.currentCollection.name: ''}
                onChange={this.handleEditCollectionName}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={5} 
                value={this.state.currentCollection ? this.state.currentCollection.description: ''}
                onChange={this.handleEditCollectionDescription}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill pull-right btn-sm"
            id="btn-save-collection"
            type="submit"
            variant="primary"
            onClick={this.saveCollection}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listings: state.listingReducer.listings,
    tasks: state.taskReducer.tasks,
    collections: state.collectionReducer.collections,
    favorite: state.collectionReducer.favorite,
    totalCount: state.listingReducer.totalCount,
    updateHey: state.listingReducer.updateHey,
    currentPopupListingId: state.miscReducer.flag[CONFIGS.FLAGS.CURRENT_POPUP_LISTING_ID]
  }
}

export default connect(mapStateToProps, { 
  getListings, 
  setFlag, 
  updateListingsHeyData, 
  postListings, 
  getAllTasks,
  getAllCollections,
  addNewCollection,
  toggleListingInCollection,
  getListing30DaysSold,
  updateCollection
})(withRouter(CollectionDetail));