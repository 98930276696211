/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";

export const getAllCollections = () => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/collection/get-all`);
}

export const addNewCollection = (collectionName) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/collection`, [
    {
      name: collectionName,
      status: 1
    }
  ]);
}

export const updateCollection = (collection) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/collection`, [
    {
      _id: collection._id,
      name: collection.name,
      description: collection.description,
      status: collection.status
    }
  ]);
}

export const toggleListingInCollection = (listing, collectionId) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/collection/toggle-listing`, {
    listing_id: listing.listing_id.toString(),
    img_cover_url: listing.img_cover_url,
    collection_id: collectionId
  });
}

export const collectionService = {
  getAllCollections,
  addNewCollection,
  toggleListingInCollection,
  updateCollection
}
