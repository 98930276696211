/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { } from '../../actions';
import { CONFIGS } from '../../configs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table
  } from "react-bootstrap";

import { FiEdit } from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";
import moment from "moment";
// https://react-icons.github.io/react-icons/icons?name=bs

class CollectionItem extends Component {
  constructor() {
    super();
    this.state = {
    };
  }


  componentDidMount() {
  }

  componentWillReceiveProps(props) {
  }

  render() {
    var collection = this.props.collection;
    return <Card className='collection-item'>
      <a href={`collection/${collection._id}`}>
        <Card.Img variant="top" src={collection.img_cover_url} />
      </a>

      {!this.props.favorite &&
        <FiEdit 
          className="btn-edit-collection" 
          title="Edit collection"
          onClick={(e) => this.props.handleShowPopupEditCollection(collection)}
        />}

      <Card.Header>
        <h5 className="text-center">{this.props.favorite? 
          <AiFillHeart className="icon-favorite"/>
          :collection.name}
        </h5>
      </Card.Header>
      <Card.Body className="text-center text-italic">
        {collection.description}
      </Card.Body>
    </Card>
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, { })(CollectionItem);